var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    class: ["app-main"]
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("keep-alive", {
    attrs: {
      include: _vm.cachedViews
    }
  }, [_c("router-view", {
    key: _vm.key,
    staticClass: "app-page-container"
  })], 1)], 1), _vm._v(" "), _c("el-backtop", {
    staticClass: "backtop",
    attrs: {
      bottom: 50,
      right: 40
    }
  }, [_c("i", {
    staticClass: "el-icon-caret-top"
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };