var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "fixed-qcode"
  }, [_c("img", {
    staticClass: "pic",
    attrs: {
      src: require("../../assets/images/evm.jpg")
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "tip"
  }, [_vm._v("扫码关注公众号")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };