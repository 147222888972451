var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tree-select"
  }, [_c("el-select", {
    ref: "selectUpResId",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    on: {
      clear: _vm.handleClear
    },
    model: {
      value: _vm.selectName,
      callback: function callback($$v) {
        _vm.selectName = $$v;
      },
      expression: "selectName"
    }
  }, [_c("el-option", {
    key: 1,
    attrs: {
      hidden: "",
      value: 1
    }
  }), _vm._v(" "), _c("el-tree", {
    ref: "treeForm",
    attrs: {
      data: _vm.data,
      "node-key": "id",
      props: _vm.defaultProps,
      "default-checked-keys": _vm.selectKeys
    },
    on: {
      "node-click": _vm.handleNodeClick
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };