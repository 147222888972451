var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-info-section"
  }, [_c("div", {
    staticClass: "avatar-box"
  }, [_vm.user.userLevel === 1 ? _c("el-image", {
    attrs: {
      src: _vm.head01,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _vm.user.userLevel === 2 ? _c("el-image", {
    attrs: {
      src: _vm.head02,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _vm.user.userLevel === 3 ? _c("el-image", {
    attrs: {
      src: _vm.head03,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _vm.user.userLevel === 4 ? _c("el-image", {
    attrs: {
      src: _vm.head04,
      fit: "fit"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.user.jobName))]), _vm._v(" "), _c("div", {
    staticClass: "host-company"
  }, [_vm._v(_vm._s(_vm.user.hospitalName))]), _vm._v(" "), _c("div", {
    staticClass: "user-type"
  }, [_vm._v(_vm._s(_vm.user.userLevelName))]), _vm._v(" "), _c("div", {
    staticClass: "level-cont",
    staticStyle: {
      color: "#ed6141"
    }
  }, [_vm.user.userLevel === 2 ? _c("el-image", {
    attrs: {
      src: _vm.level02,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _vm.user.userLevel === 3 ? _c("el-image", {
    attrs: {
      src: _vm.level03,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v(" "), _vm.user.userLevel === 4 ? _c("el-image", {
    attrs: {
      src: _vm.level04,
      fit: "fit"
    }
  }) : _vm._e(), _vm._v("\n    " + _vm._s(_vm.user.userLevel === 2 ? "初级用户" : _vm.user.userLevel === 3 ? "中级用户" : _vm.user.userLevel === 4 ? "高级用户" : "录入员") + "\n  ")], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };