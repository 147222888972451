import request from '@/utils/request';
export function researchAll() {
  return request({
    url: '/api/web/allow/researchAll',
    method: 'get'
  });
}
export function getCity(data) {
  return request({
    url: "/api/web/allow/hospital",
    method: 'get',
    params: data
  });
}
export function getCode(phone) {
  return request({
    url: "/api/web/allow/registerCode?phone=".concat(phone),
    method: 'get'
  });
}
export function register(data) {
  return request({
    url: '/api/web/user/register',
    method: 'post',
    data: data
  });
}
// export function logout() {
//   return request({
//     url: 'auth/logout',
//     method: 'delete'
//   })
// }