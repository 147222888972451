import FieldCoreName from './CoreName';
import FieldCoreType from './CoreType';
import FieldCoreDescribe from './CoreDescribe';
import FieldCoreMulti from './CoreMulti';
import FieldCoreSplit from './CoreSplit';
import FieldCoreVisible from './CoreVisible';
import FieldCoreWidth from './CoreWidth';
import FieldCoreTip from './CoreTip';
import FieldCoreNote from './CoreNote';
import FieldCoreUpload from './CoreUpload';
var formItems = [
// 公共组件
{
  name: 'FieldCoreName',
  com: FieldCoreName
}, {
  name: 'FieldCoreType',
  com: FieldCoreType
}, {
  name: 'FieldCoreDescribe',
  com: FieldCoreDescribe
}, {
  name: 'FieldCoreMulti',
  com: FieldCoreMulti
}, {
  name: 'FieldCoreSplit',
  com: FieldCoreSplit
}, {
  name: 'FieldCoreVisible',
  com: FieldCoreVisible
}, {
  name: 'FieldCoreWidth',
  com: FieldCoreWidth
}, {
  name: 'FieldCoreTip',
  com: FieldCoreTip
}, {
  name: 'FieldCoreNote',
  com: FieldCoreNote
}, {
  name: 'FieldCoreUpload',
  com: FieldCoreUpload
}];
var formCoreFields = {
  install: function install(Vue, options) {
    formItems.forEach(function (item) {
      Vue.component(item.name, item.com);
    });
  }
};
export default formCoreFields;