function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapGetters } from 'vuex';
import TreeSelect from '../../tool/TreeSelect';
import { vsFLInfo, vsOptionList } from '@/api/form/field';
import { VAR_TRIGGER_OPT, VAR_TRIGGER_RELATION, VAR_TRIGGER_LOGIC, VAR_TRIGGER_CONDITION } from '@/const/enum/form';
import { deepClone, object2tn, object2tv } from '@/utils';
import { IS_SIGN_TEMP } from '@/const/enum/form';
var fl_type = function fl_type(type) {
  return [5, 6, 7, 8].includes(type);
};
var sz_type = function sz_type(type) {
  return [1].includes(type);
};
var moduleTreeDataKeys = {};
function formatTreeData() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var childKey = arguments.length > 1 ? arguments[1] : undefined;
  data.forEach(function (v) {
    if (v.id) {
      moduleTreeDataKeys[v.id] = v;
    }
    if (v[childKey] && v[childKey].length) {
      v.children = v[childKey];
      delete v[childKey];
      formatTreeData(v.children, 'detailVos');
    }
  });
}
export default {
  name: 'CoreFormVisible',
  components: {
    TreeSelect: TreeSelect
  },
  props: {
    type: Number,
    varTriggerOpt: Array
  },
  data: function data() {
    return {
      isFLType: fl_type,
      isSZType: sz_type,
      treeKey: {},
      treeData: [],
      condition: this.type,
      control: [],
      varLogic: [],
      varRelation: [],
      varCondition: [],
      childOpt: [],
      numValue: null
    };
  },
  computed: _objectSpread({}, mapGetters(['formSelectField', 'formFieldEdit'])),
  watch: {
    'varTriggerOpt': {
      handler: function handler(value) {
        this.control = deepClone(value);
        this.initData(value);
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.getTreeData();
  },
  methods: {
    initData: function initData(value) {
      var _this = this;
      value.forEach(function (v, i) {
        if (_this.isFLType(v.varType)) {
          _this.getFLOption(v, i);
        } else if (_this.isSZType(v.varType)) {
          _this.getSZOption(v, i);
        }
        _this.varLogic[i] = deepClone(object2tn(VAR_TRIGGER_LOGIC));
      });
    },
    asyncOptCondition: function asyncOptCondition(type) {
      var data = deepClone(object2tn(VAR_TRIGGER_CONDITION));
      return type === 5 || type === 7 ? data.filter(function (v) {
        return v.value === 1;
      }) : data;
    },
    getTreeData: function getTreeData() {
      var _this2 = this;
      var selectGroup = this.$store.state.form.selectGroup;
      var rsId = this.$route.query.researchId;
      if (!selectGroup) {
        return;
      }
      var params = {
        researchId: rsId
      };
      if (!this.formSelectField.isNew()) {
        params.id = this.formSelectField.id;
      }
      if (IS_SIGN_TEMP()) {
        params.tempsta = IS_SIGN_TEMP();
      }
      vsFLInfo(params).then(function (res) {
        if (res.data) {
          formatTreeData(res.data, 'groupVos');
          _this2.treeData = res.data;
          _this2.treeKey = moduleTreeDataKeys;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    resetVarItem: function resetVarItem(index) {
      var _this3 = this;
      Object.keys(this.control[index]).forEach(function (k) {
        _this3.control[index][k] = deepClone(VAR_TRIGGER_OPT)[k];
      });
      console.log(this.control);
    },
    changeVarItem: function changeVarItem(item, index) {
      var _this4 = this;
      // this.resetVarItem(index)
      this.$nextTick(function () {
        _this4.control[index].opts = [];
        _this4.control[index].varId = item.id;
        _this4.control[index].varType = item.typeCode;
        if (_this4.isFLType(_this4.control[index].varType)) {
          _this4.getFLOption(_this4.control[index], index);
        } else if (_this4.isSZType(_this4.control[index].varType)) {
          _this4.getSZOption(_this4.control[index], index);
        }
        _this4.$emit('update:varTriggerOpt', _this4.control);
      });
    },
    // 分类变量请求option
    getFLOption: function getFLOption(item, index) {
      var _this5 = this;
      this.varCondition[index] = this.asyncOptCondition(item.varType);
      vsOptionList({
        varId: item.varId
      }).then(function (res) {
        _this5.$set(_this5.childOpt, index, res.data || []);
      });
    },
    getSZOption: function getSZOption(item, index) {
      this.varRelation[index] = deepClone(object2tv(VAR_TRIGGER_RELATION));
    },
    addVarItem: function addVarItem() {
      this.control.push(deepClone(VAR_TRIGGER_OPT));
      this.change();
    },
    delVarItem: function delVarItem(index) {
      this.control.splice(index, 1);
      this.change();
    },
    change: function change() {
      console.log(this.condition, this.control);
      this.$emit('update:type', this.condition);
      this.$emit('update:varTriggerOpt', this.control);
    }
  }
};