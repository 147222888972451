import { constantRouterMap } from '@/router/routers';
import Layout from '@/layout/index';
import ParentView from '@/components/ParentView';
import { flatten } from '@/utils';
import { RESEARCH_PERSON_ROLE_MENU, ROUTER_LEVEL } from '@/const/enum/role';
import { RESEARCH_PERSON_ADMIN } from '../../const/enum/role';
import { researchConfigLock } from '@/api/page/research';
var CARD_BASE_PERMISSION = {
  'show_patient_record': true,
  'edit_patien_record': true,
  'research_configuration': true,
  'telephone_follow_up': true,
  'patient_interaction': true,
  'import_patient_record': true,
  'export_patient_record': true,
  'show_research_report': true,
  'follow_up_data_analysis': true
};
var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    sidebarRouters: [],
    zIndex: 1,
    researchRolePermission: CARD_BASE_PERMISSION
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routers) {
      state.sidebarRouters = constantRouterMap.concat(routers);
    },
    SET_SIDEBAR_ZINDEX: function SET_SIDEBAR_ZINDEX(state, index) {
      state.zIndex = index;
    },
    SET_RESEARCH_ROLE_PERMISSION: function SET_RESEARCH_ROLE_PERMISSION(state, info) {
      console.log('infoinfoinfoinfoinfoinfo', info);
      if (info && Object.keys(info).length) {
        state.researchRolePermission = info;
      } else {
        state.researchRolePermission = CARD_BASE_PERMISSION;
      }
    },
    UPDATE_SIDEBAR_ROUTERS: function UPDATE_SIDEBAR_ROUTERS(state, routers) {}
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, asyncRouter) {
      var commit = _ref.commit;
      commit('SET_ROUTERS', asyncRouter);
    },
    SetSidebarRouters: function SetSidebarRouters(_ref2, _ref3) {
      var commit = _ref2.commit,
        dispatch = _ref2.dispatch;
      var to = _ref3.to,
        from = _ref3.from,
        menu = _ref3.menu,
        sus = _ref3.sus;
      return new Promise(function (resolve) {
        var parsePage = ['/research/preview'];
        var lockPage = ['/itemStudy/config'];
        var root = to.matched[0];
        // 显示二级菜单
        if (root.meta.zIndex === 2) {
          dispatch('SetShowSecondRouters');
        } else {
          // 显示一级菜单
          dispatch('SetShowFirstRouters');
          console.log('一级页面', menu);
        }
        commit('SET_SIDEBAR_ROUTERS', menu);
        // 如果进入是二级页面，加载研究信息
        if (to.query.id && to.query.researchId) {
          // dispatch('GetMedRecNVarTips', to.query.researchId)
          commit('SET_SELECT_FIELD', {});
          if (root.meta.zIndex === 2 && !parsePage.includes(to.path)) {
            console.log('to.pathto.pathto.pathto.pathto.pathto.pathto.pathto.path', to.path);
            dispatch('GetResearchRolePermission', {
              researchId: to.query.researchId,
              path: to.path
            }).then(function (res) {
              // 进入配置页面-增加锁
              if (lockPage.includes(to.path)) {
                dispatch('AddResearchConfigLock', to.query.researchId).then(function (res) {
                  if (!res.data) {
                    resolve('此研究正在被其他用户编辑，暂时无法查看研究配置');
                  } else {
                    resolve();
                  }
                });
                // 离开配置页面-删除锁
              } else if (lockPage.includes(from.path)) {
                dispatch('DeleteTemp');
                resolve();
              } else {
                resolve();
              }
            });
          } else {
            dispatch('GetResearchInfo', to.query.researchId).then(function (res) {
              console.log(res);
              resolve();
            });
          }
        } else {
          resolve();
        }
      });
    },
    SetShowFirstRouters: function SetShowFirstRouters(_ref4) {
      var commit = _ref4.commit;
      var secondRoutes = constantRouterMap.filter(function (v) {
        return v.meta && v.meta.zIndex === 2;
      });
      secondRoutes.forEach(function (v) {
        v.hidden = true;
      });
      var firstRoutes = constantRouterMap.filter(function (v) {
        return v.meta && v.meta.zIndex === 1 && v.name !== 'ResearchMain';
      });
      var user = window.$store.getters.user; // 1录入员
      var ROUTER_ARR = [];
      if (user.userLevel === 1 || user.auditStatus === 0 || user.auditStatus === -1) {
        ROUTER_ARR = ROUTER_LEVEL;
      }
      firstRoutes.forEach(function (v) {
        v.hidden = false;
        if (ROUTER_ARR.includes(v.path)) {
          v.hidden = true;
        }
      });
      console.log('storestorestorestore', window.$store.getters.user.userLevel);
      console.log('firstRoutesfirstRoutesfirstRoutesfirstRoutes', firstRoutes);
      commit('SET_SIDEBAR_ZINDEX', 1);
    },
    SetShowSecondRouters: function SetShowSecondRouters(_ref5) {
      var commit = _ref5.commit;
      var firstRoutes = constantRouterMap.filter(function (v) {
        return v.meta && v.meta.zIndex === 1;
      });
      firstRoutes.forEach(function (v) {
        v.hidden = true;
      });
      // const secondRoutes = constantRouterMap.filter(v => v.meta && v.meta.zIndex === 2)
      // secondRoutes.forEach(v => {
      //   v.hidden = false
      // })
      commit('SET_SIDEBAR_ZINDEX', 2);
    },
    SetResearchRoleRouters: function SetResearchRoleRouters(_ref6, data) {
      var commit = _ref6.commit,
        state = _ref6.state;
      var routes = state.sidebarRouters;
      var researchRole = data.levelName;
      var secondRoutes = routes.filter(function (v) {
        return v.meta && v.meta.zIndex === 2;
      });
      var reqRoleMenu = RESEARCH_PERSON_ROLE_MENU[researchRole];
      var allPath = reqRoleMenu.map(function (v) {
        return v.path;
      });
      var flatAllPath = flatten(allPath);
      // 管理员权限控制
      if (!researchRole || researchRole === RESEARCH_PERSON_ADMIN) {
        secondRoutes.forEach(function (v) {
          v.hidden = false;
        });
        var studyConfig = secondRoutes.find(function (v) {
          return v.name === 'CardItemStudyConfig';
        });
        studyConfig.hidden = data.standard === '1';
        var followUp = constantRouterMap.find(function (v) {
          return v.name === 'CardPatientManageFollowUp';
        });
        followUp.hidden = !data.isSetFollowupRemind;
        // 研究员、录入员权限控制
      } else {
        var childMenu = RESEARCH_PERSON_ROLE_MENU[researchRole];
        var currentMenu = childMenu.find(function (v) {
          return v.path === data.toPath;
        });
        if (!flatAllPath.includes(data.toPath)) {
          return window.$router.push('/404');
        }
        if (childMenu && currentMenu.permission && !state.researchRolePermission[currentMenu.permission]) {
          return window.$router.push('/404');
        }
        secondRoutes.forEach(function (v) {
          if (childMenu && !childMenu.length) {
            v.hidden = true;
          } else {
            var hasMenu = childMenu.find(function (k) {
              return k.path === v.path;
            });
            if (!hasMenu) {
              v.hidden = true;
            } else if (hasMenu && hasMenu.permission && !state.researchRolePermission[hasMenu.permission]) {
              v.hidden = true;
            } else {
              v.hidden = false;
            }
          }
        });
        var _followUp = constantRouterMap.find(function (v) {
          return v.name === 'CardPatientManageFollowUp';
        });
        _followUp.hidden = !data.isSetFollowupRemind;
      }
    },
    AddResearchConfigLock: function AddResearchConfigLock(_ref7, id) {
      var commit = _ref7.commit,
        state = _ref7.state;
      return researchConfigLock({
        id: id
      });
    }
  }
};
export var filterAsyncRouter = function filterAsyncRouter(routers) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  // 遍历后台传来的路由字符串，转换为组件对象
  return routers.filter(function (router) {
    if (type && router.children) {
      router.children = filterChildren(router.children);
    }
    if (router.component) {
      if (router.component === 'Layout') {
        // Layout组件特殊处理
        router.component = Layout;
      } else if (router.component === 'ParentView') {
        router.component = ParentView;
      } else {
        var component = router.component;
        router.component = loadView(component);
      }
    }
    if (router.children != null && router.children && router.children.length) {
      router.children = filterAsyncRouter(router.children, router, type);
    } else {
      delete router['children'];
      delete router['redirect'];
    }
    return true;
  });
};
function filterChildren(childrenMap) {
  var lastRouter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(function (c) {
          c.path = el.path + '/' + c.path;
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }
          children.push(c);
        });
        return;
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path;
    }
    children = children.concat(el);
  });
  return children;
}
export var loadView = function loadView(view) {
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
export default permission;