import request from '@/utils/request';

// 研究-分组-变量详细
export function moduleGroupVs(params) {
  return request({
    url: "/api/web/system/var/detail/list/group/hole",
    method: 'get',
    params: params
  });
}

// 新建变量
export function addVs(data) {
  return request({
    url: "/api/web/system/var/detail/create",
    method: 'post',
    data: data
  });
}

// 删除变量
export function delVs(ids) {
  return request({
    url: "/api/web/system/var/detail/".concat(ids),
    method: 'delete'
  });
}

// 修改变量
export function updateVs(data) {
  return request({
    url: "/api/web/system/var/detail/modify",
    method: 'post',
    data: data
  });
}

// 排序变量
export function sortVs(data) {
  return request({
    url: "/api/web/system/var/detail/sort",
    method: 'post',
    data: data
  });
}

// 新建变量选项
export function addVsOption(data) {
  return request({
    url: "/api/web/system/var/opt/new",
    method: 'post',
    data: data
  });
}

// 删除变量选项
export function delVsOption(id) {
  return request({
    url: "/api/web/system/var/opt/".concat(id),
    method: 'get'
  });
}

// 变量选项
export function vsOptionList(params) {
  return request({
    url: "/api/web/system/var/opt/list",
    method: 'get',
    params: params
  });
}

// 分类变量TREE列表
export function vsFLInfo(data) {
  return request({
    url: "/api/web/system/var/detail/list/tree",
    method: 'post',
    data: data
  });
}

// 校验字段名
export function checkVsName(data) {
  return request({
    url: "/api/web/system/var/detail/check/name",
    method: 'post',
    data: data
  });
}

// 引用变量
export function quoteVsInfo(data) {
  return request({
    url: "/api/web/system/var/detail/load",
    method: 'post',
    data: data
  });
}

// 判断是否是触发变量
export function isTriggerVs(params) {
  return request({
    url: "/api/web/system/var/detail/check/triggered",
    method: 'get',
    params: params
  });
}

// 变量配置导出
export function downloadVsSetting(params) {
  return request({
    url: "/api/web/system/var/detail/export",
    method: 'get',
    responseType: 'blob',
    params: params
  });
}