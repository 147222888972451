import axios from 'axios';
import router from '@/router/routers';
import { Message } from 'element-ui';
import store from '../store';
import { getToken } from '@/utils/auth';
import Config from '@/settings';
import Cookies from 'js-cookie';
var limitErrMsgUrl = ['/api/web/system/research/save'];

// 创建axios实例
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // config.headers['Content-Type'] = 'application/json'
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  var code = response.status;
  if (code < 200 || code > 300) {
    Message.error(response.data.msg);
    return Promise.reject('error');
  } else {
    try {
      if (!response.data.code || response.data.code === 200 || response.data.code === 400004) {
        return response.data;
      } else {
        if (response.data.code === 600125 || response.data.code === 500011 || response.data.code === 401) {
          store.dispatch('LogOut').then(function () {
            location.reload();
            if (!window.sessionStorage.getItem('LoginAgain')) {
              window.sessionStorage.setItem('LoginAgain', response.data.code);
              window.sessionStorage.setItem('LoginMsg', response.data.msg || response.data.message);
            }
          });
        } else {
          if (!limitErrMsgUrl.includes(response.config.url)) {
            Message.error(response.data.msg || response.data.message || '未知错误');
          } else {
            if (response.data.msg || response.data.message) {
              Message.error(response.data.msg || response.data.message);
            }
          }
          return Promise.reject('error');
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
}, function (error) {
  var code = 0;
  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Message.error('网络请求超时');
      // Notification.error({
      //   title: '网络请求超时',
      //   duration: 5000
      // })
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      store.dispatch('LogOut').then(function () {
        // 用户登录界面提示
        Cookies.set('point', 401);
        location.reload();
        window.sessionStorage.setItem('LoginAgain', code);
      });
    } else if (code === 403) {
      router.push({
        path: '/401'
      });
    } else if (code === 404) {
      Message.error('404 Not Found');
      // Notification.error({
      //   title: '404 Not Found',
      //   duration: 5000
      // })
    } else {
      var errorMsg = error.response.data.msg;
      if (errorMsg !== undefined) {
        Message.error(errorMsg);
        // Notification.error({
        //   title: errorMsg,
        //   duration: 5000
        // })
      }
    }
  } else {
    Message.error('网络连接失败');
    // Notification.error({
    //   title: '接口请求失败',
    //   duration: 5000
    // })
  }

  return Promise.reject(error);
});
export default service;