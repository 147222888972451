function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import router from './routers';
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie
import { buildMenus } from '@/api/system/menu';
import { Message } from 'element-ui';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration
var whiteList = ['/login', '/register']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  var routeValid = function routeValid(error) {
    if (error) {
      Message.error(error);
      NProgress.done();
    } else {
      next();
    }
  };
  console.log('路由跳转信息', to, from, getToken(), store.getters.roles);
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title;
  }
  NProgress.start();
  if (getToken()) {
    console.log('我有getToken,所以我进来了');
    store.commit('SET_TOKEN', getToken());
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      console.log('跳转login');
      next();
      NProgress.done();
    } else {
      console.log('否则');
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        console.log('判断当前用户是否已拉取完user_info信息');
        store.dispatch('GetInfo').then(function () {
          // 拉取user_info
          // 动态路由，拉取菜单
          // loadMenus(next, to. from)
          if (to.matched.length === 0) {
            next('/404');
          } else {
            store.dispatch('SetSidebarRouters', {
              to: to,
              from: from,
              menu: []
            }).then(function (error) {
              routeValid(error);
            });
          }
        }).catch(function () {
          store.dispatch('LogOut').then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
        // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        console.log('登录时未拉取 菜单，在此处拉取');
        // 修改成false，防止死循环
        store.dispatch('SetSidebarRouters', {
          to: to,
          from: from,
          menu: []
        }).then(function (error) {
          store.dispatch('updateLoadMenus');
          routeValid(error);
        });
        // loadMenus(next, to)
        next();
      } else {
        store.dispatch(store.getters.formStepIndex || store.getters.formStepIndex === 0 ? 'HandlerEditFieldLeaveActionTow' : 'HandlerEditFieldLeaveAction', function () {
          store.dispatch('SetSidebarRouters', {
            to: to,
            from: from,
            menu: []
          }).then(function (error) {
            routeValid(error);
          });
        });
      }
    }
  } else {
    store.commit('SET_TOKEN', undefined);
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1 || to.meta.static) {
      // 在免登录白名单，直接进入
      console.log('在免登录白名单，直接进入');
      next();
    } else {
      next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页
      console.log('否则全部重定向到登录页');
      NProgress.done();
    }
  }
});
export var loadMenus = function loadMenus(next, to, from) {
  buildMenus().then(function (res) {
    var sdata = JSON.parse(JSON.stringify(res));
    var rdata = JSON.parse(JSON.stringify(res));
    var sidebarRoutes = filterAsyncRouter(sdata);
    var rewriteRoutes = filterAsyncRouter(rdata, false, true);
    rewriteRoutes.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    store.dispatch('GenerateRoutes', rewriteRoutes).then(function () {
      // 存储路由
      router.addRoutes(rewriteRoutes); // 动态添加可访问路由表
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
    store.dispatch('SetSidebarRouters', {
      to: to,
      from: from,
      menu: sidebarRoutes
    });
  });
};
router.afterEach(function () {
  NProgress.done(); // finish progress bar
});