var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "logo-box"
  }, [_c("router-link", {
    attrs: {
      to: "/research/list"
    }
  }, [_c("el-image", {
    staticClass: "logo",
    attrs: {
      src: _vm.Logo,
      fit: "cover"
    }
  })], 1)], 1)]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [!_vm.isLogin ? _c("router-link", {
    attrs: {
      to: "/login?redirect=".concat(_vm.$route.path)
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_vm._v("登录/注册")])], 1) : [_vm.user.jobName ? _c("span", {
    staticClass: "welcome"
  }, [_vm._v(_vm._s(_vm.user.jobName) + "，您好")]) : _vm._e(), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/research/list"
    }
  }, [_c("span", {
    staticClass: "module study"
  }, [_vm._v("我的研究列表")])]), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/userCenter"
    }
  }, [_c("span", {
    staticClass: "module user"
  }, [_vm._v("个人中心")])]), _vm._v(" "), _c("span", {
    staticClass: "module logout",
    on: {
      click: _vm.open
    }
  }, [_vm._v("退出登录")])]] : _vm._e()], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "uat-tip",
    staticStyle: {
      "font-size": "19px"
    }
  }, [_c("p", [_c("i", {
    staticClass: "el-icon-warning"
  }), _vm._v(" 本环境为UAT测试环境，请勿录入真实数据！")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };