var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_c("navbar"), _vm._v(" "), _vm.$route.meta.full ? _c("div", {
    staticClass: "full-wrapper"
  }, [_c("app-main"), _vm._v(" "), _c("fix-tool"), _vm._v(" "), _c("Footer")], 1) : _c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_c("app-main"), _vm._v(" "), _c("Footer"), _vm._v(" "), _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(), _vm._v(" "), _c("sidebar", {
    staticClass: "sidebar-container"
  })], 1), _vm._v(" "), _c("Theme", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "theme"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };