var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    attrs: {
      disabled: _vm.formFieldEdit && _vm.formEditable ? false : true
    }
  }, [_c("div", {
    staticClass: "item-field"
  }, [_c("div", {
    staticClass: "label"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "在页面上显示一些文本说明"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v(_vm._s(_vm.label)), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")])], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 3,
      placeholder: "请输入内容"
    },
    on: {
      input: function input($event) {
        return _vm.change();
      }
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };