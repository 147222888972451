import request from '@/utils/request';

// 所有字段类型
export function formTypeList(params) {
  return request({
    url: "/api/web/system/var/type/list",
    method: 'get'
  });
}

// 研究-新增分组
export function formAddGroup(data) {
  return request({
    url: "/api/web/system/var/module/create",
    method: 'post',
    data: data
  });
}

// 研究-删除分组
export function formDelGroup(id) {
  return request({
    url: "/api/web/system/var/module/".concat(id),
    method: 'delete'
  });
}

// 研究-修改分组
export function formEditGroup(data) {
  return request({
    url: "/api/web/system/var/module/edit",
    method: 'put',
    data: data
  });
}

// 研究-排序分组
export function formSortGroup(data) {
  return request({
    url: "/api/web/system/var/module/sort",
    method: 'post',
    data: data
  });
}

// 研究-分组列表
export function formGroupList(params) {
  return request({
    url: "/api/web/system/var/module/list",
    method: 'get',
    params: params
  });
}