import Cookies from 'js-cookie';
import Config from '@/settings';
var TokenKey = Config.TokenKey;

// 需求cookie保持6小时
var seconds = 1000 * 60 * 60 * 6;
export function expiresTime() {
  return new Date(new Date() * 1 + seconds);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, {
      expires: Config.tokenCookieExpires
    });
  } else return Cookies.set(TokenKey, token, {
    expires: expiresTime()
  });
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}