import _ from 'lodash';
var COMMON_FORM_PROPERTY = ['varName', 'sort'];
export var SPECIAL_CODE = ['PatName', 'MedRecN'];

// 触发规则选项
export var VAR_TRIGGER_OPT = {
  id: null,
  logic: 1,
  // 且|或
  opts: [],
  // 分类的选项值或者数值的大小
  relates: '1',
  // 大于/等于/小于等等
  showCondition: 1,
  // 选择任意一项|同时选择
  varId: null,
  // 关联变量ID
  varType: null // 关联变量类型
};

// 分类变量选项
export var VAR_FL_OPT = {
  additional: 0,
  content: '',
  id: null,
  serialNum: '1',
  varId: null
};
export var EXCLUSIVE_RULE_OPT = {
  lr: '',
  rr: []
};
export var VAR_TRIGGER_RELATION = {
  1: '等于',
  2: '不等于',
  3: '大于',
  4: '大于等于',
  5: '小于',
  6: '小于等于',
  7: '数值范围'
};
export var VAR_TRIGGER_CHOOSE = {
  1: '早于',
  2: '不早于',
  3: '晚于',
  4: '不晚于',
  5: '数值范围'
};
export var VAR_TRIGGER_RELATION_RANGE = 'a >= b && a <= c';
export var VAR_TRIGGER_RELATION_JS = {
  1: '==',
  2: '!=',
  3: '>',
  4: '>=',
  5: '<',
  6: '<=',
  7: VAR_TRIGGER_RELATION_RANGE
};
export var VAR_TRIGGER_CONDITION = {
  1: '选择任意一项',
  2: '同时选择'
};
export var VAR_TRIGGER_LOGIC = {
  1: '且',
  2: '或'
};
export var VAR_TRIGGER_LOGIC_JS = {
  1: '&&',
  2: '||'
};
export var IS_SIGN_TEMP = function IS_SIGN_TEMP() {
  return window.location.href.indexOf('itemStudy/config') > -1 ? 1 : null;
};
export var BASE_FORM_FIELD = {
  researchId: '',
  // 研究ID
  moduleId: '',
  groupId: '',
  typeCode: 1,
  // 变量类型
  typeId: 1,
  // 变量类型id
  isNeed: undefined,
  // 字段是否必填
  editable: 1,
  // 字段是否可编辑
  varName: '',
  // 变量名称
  dateFmt: '',
  // 日期格式 1 精确到日 2 精确到时分
  comment: '',
  // 注释内容
  dict: '',
  // 数据字典
  dcm: undefined,
  // 数值类型限制小数点长度
  divideLine: 0,
  // 分割线  0 无 1 上分隔线 2 下分隔线
  exclusiveRule: '',
  // 互斥规则 互斥内部用/分割，互斥源内部多个用|隔开，多个规则用;分隔
  hint: '',
  // 变量提示
  length: 1,
  // 输入框宽度 1 短 2 中 3 长
  units: [''],
  // 单位 多个单位用;分隔
  maxValue: undefined,
  // 最大值
  minValue: undefined,
  // 最小值
  multiple: 0,
  // 是否可添加多条 0 否 1是
  picName: '',
  // 图片名称
  picUrl: '',
  // 图片URL
  spr: '1',
  // 分隔符
  showCondition: 1,
  // 显示条件 1 始终显示 2其他条件触发
  sort: undefined,
  opted: undefined,
  // 默认值 最大值/最小值/单位/多值变量分隔符/选项默认值/日期格式/文本注释内容
  optSort: 0,
  // 分类选项排序规则。0或者不存在默认排序，传入数字按每行N个
  triggerVar: '',
  // 触发变量
  triggerOpt: '',
  // 触发变量选项
  triggerVarId: '',
  // 触发变量id
  valueNum: null,
  // 值变量
  varCode: null,
  varGroup: '',
  // 变量分组id
  varGroupName: '',
  // 变量分组名称
  varInput: '',
  // 变量输入内容，
  varStandard: '',
  // 单选类型
  varTriggerDTOS: null,
  // 变量触发规则 -> VAR_TRIGGER_OPT
  varOpts: [],
  // 分类变量下拉选择
  child: [],
  // 变量组
  tempsta: null // 是否是临时字段 1 是 null 正式
};

export var getInitFieldByType = function getInitFieldByType() {
  var typeCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var form = _.cloneDeep(BASE_FORM_FIELD);
  if (typeCode === 1) {
    form.length = 1;
  } else if (typeCode === 2) {
    form.length = 2;
  } else if (typeCode === 3) {
    form.length = 2;
  } else if (typeCode === 4) {
    form.dateFmt = 3;
  } else if (typeCode === 5) {
    form.varOpts = [_.cloneDeep(VAR_FL_OPT)];
  } else if (typeCode === 6) {
    form.varOpts = [_.cloneDeep(VAR_FL_OPT)];
    form.exclusiveRule = [_.cloneDeep(EXCLUSIVE_RULE_OPT)];
  } else if (typeCode === 7) {
    form.varOpts = [_.cloneDeep(VAR_FL_OPT)];
  } else if (typeCode === 8) {
    form.varOpts = [_.cloneDeep(VAR_FL_OPT)];
    form.exclusiveRule = [_.cloneDeep(EXCLUSIVE_RULE_OPT)];
  } else if (typeCode === 12) {
    form.length = 1;
  }
  form.typeCode = typeCode;
  form.tempsta = IS_SIGN_TEMP();
  form.varTriggerDTOS = [_.cloneDeep(VAR_TRIGGER_OPT)];
  return form;
};
export var getCloneFieldByType = function getCloneFieldByType() {
  var typeCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var originForm = arguments.length > 1 ? arguments[1] : undefined;
  var form = getInitFieldByType(typeCode);
  COMMON_FORM_PROPERTY.forEach(function (k) {
    form[k] = _.cloneDeep(originForm[k]);
  });
  if (originForm.varOpts && originForm.varOpts.length) {
    form['varOpts'] = _.cloneDeep(originForm.varOpts);
  }
  console.log('类型切换', form);
  return form;
};