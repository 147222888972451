import request from '@/utils/request';

// 研究-新建模块分组
export function addGroupVs(data) {
  return request({
    url: "/api/web/system/var/group/create",
    method: 'post',
    data: data
  });
}

// 研究-编辑模块分组
export function editGroupVs(data) {
  return request({
    url: "/api/web/system/var/group/edit",
    method: 'put',
    data: data
  });
}

// 研究-排序模块分组
export function sortGroupVs(data) {
  return request({
    url: "/api/web/system/var/group/sort",
    method: 'post',
    data: data
  });
}

// 研究-删除模块分组
export function delGroupVs(id) {
  return request({
    url: "/api/web/system/var/group/delete/".concat(id),
    method: 'put'
  });
}
// 查询研究变量数量剩余
export function getRepertory(params) {
  return request({
    url: "/api/web/system/var/detail/vars/repertory",
    method: 'get',
    params: params
  });
}
// 查询病种下其他研究中的变量
export function getDiseaseVars(params) {
  return request({
    url: "/api/web/system/var/detail/disease/vars",
    method: 'get',
    params: params
  });
}
// 批量引用变量
export function setBatch(data) {
  return request({
    url: "/api/web/system/var/detail/load/batch",
    method: 'post',
    data: data
  });
}