var _RESEARCH_PERSON_ROLE;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var ItemStudyAsyncChildMenu = {
  ItemStudySummary: {
    path: '/itemStudy/list'
  },
  ItemStudyConfig: {
    path: '/itemStudy/config',
    permission: 'research_configuration'
  },
  ItemStudyChallenge: {
    path: '/itemStudy/challenge'
  },
  ItemStudyReport: {
    path: '/itemStudy/report',
    permission: 'show_research_report'
  },
  ItemStudyAnalysis: {
    path: '/itemStudy/analysis',
    permission: 'show_research_report'
  },
  PatientManageList: {
    path: '/patientManage/list'
  },
  PatientManageDetail: {
    path: '/patientManage/detail'
  },
  PatientManageFollowUp: {
    path: '/patientManage/followUp'
  },
  PatientManageQuery: {
    path: '/patientManage/query'
  },
  PatientManageInteract: {
    path: '/patientManage/interact',
    permission: 'patient_interaction'
  },
  PatientManageImport: {
    path: '/patientManage/import'
  },
  PersonManageList: {
    path: '/personManage/list'
  }
};
export var RESEARCH_PERSON_ADMIN = '管理员';
export var RESEARCH_PERSON_STUDY = '研究员';
export var RESEARCH_PERSON_ENTER = '录入员';
export var ROUTER_LEVEL = ['/integralManage', '/footprint'];
export var RESEARCH_PERSON_ROLE_MENU = (_RESEARCH_PERSON_ROLE = {}, _defineProperty(_RESEARCH_PERSON_ROLE, RESEARCH_PERSON_ADMIN, [ItemStudyAsyncChildMenu.ItemStudySummary, ItemStudyAsyncChildMenu.ItemStudyConfig, ItemStudyAsyncChildMenu.ItemStudyChallenge, ItemStudyAsyncChildMenu.ItemStudyReport, ItemStudyAsyncChildMenu.ItemStudyAnalysis, ItemStudyAsyncChildMenu.PatientManageList, ItemStudyAsyncChildMenu.PatientManageDetail, ItemStudyAsyncChildMenu.PatientManageFollowUp, ItemStudyAsyncChildMenu.PatientManageQuery, ItemStudyAsyncChildMenu.PatientManageInteract, ItemStudyAsyncChildMenu.PatientManageImport, ItemStudyAsyncChildMenu.PersonManageList]), _defineProperty(_RESEARCH_PERSON_ROLE, RESEARCH_PERSON_STUDY, [ItemStudyAsyncChildMenu.ItemStudySummary, ItemStudyAsyncChildMenu.ItemStudyConfig, ItemStudyAsyncChildMenu.ItemStudyChallenge, ItemStudyAsyncChildMenu.ItemStudyReport, ItemStudyAsyncChildMenu.ItemStudyAnalysis, ItemStudyAsyncChildMenu.PatientManageList, ItemStudyAsyncChildMenu.PatientManageDetail, ItemStudyAsyncChildMenu.PatientManageFollowUp, ItemStudyAsyncChildMenu.PatientManageInteract, ItemStudyAsyncChildMenu.PatientManageImport]), _defineProperty(_RESEARCH_PERSON_ROLE, RESEARCH_PERSON_ENTER, [ItemStudyAsyncChildMenu.ItemStudyConfig, ItemStudyAsyncChildMenu.ItemStudyChallenge,
// ItemStudyAsyncChildMenu.ItemStudyAnalysis,
ItemStudyAsyncChildMenu.PatientManageList, ItemStudyAsyncChildMenu.PatientManageDetail, ItemStudyAsyncChildMenu.PatientManageFollowUp, ItemStudyAsyncChildMenu.PatientManageInteract, ItemStudyAsyncChildMenu.PatientManageImport]), _RESEARCH_PERSON_ROLE);
export var RESEARCH_PERSON_ROLE = {
  1: '管理员',
  2: '研究员',
  3: '录入员'
};