var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    attrs: {
      disabled: !_vm.formFieldEdit
    }
  }, [_c("div", {
    staticClass: "item-field horizontal"
  }, [_c("div", {
    staticClass: "label bw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "可以为变量设置上下分割线，用来做区域划分"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("是否添加分割线\n    ")], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-checkbox-group", {
    on: {
      change: _vm.change
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: 1
    }
  }, [_vm._v("上分割线")]), _vm._v(" "), _c("el-checkbox", {
    attrs: {
      label: 2
    }
  }, [_vm._v("下分割线")])], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };