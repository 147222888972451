import { VsGroup } from './vsGroup';
import { SPECIAL_CODE } from '../enum/form';

/**
 * 研究模块变量
 * @returns {*}
 * @constructor
 */
export function VsModule() {
  if (!(this instanceof VsModule)) {
    return new VsModule().initialize(arguments);
  } else if (arguments.length) {
    this.initialize(arguments);
  }
}
var specialCodeArr = [];
function getVsByCode(list) {
  list.forEach(function (v) {
    if (v.hasOwnProperty('varCode')) {
      specialCodeArr.push(v.varCode);
      if (v.child && v.child.length) {
        getVsByCode(v.child);
      }
    } else {
      if (v.child && v.child.length) {
        getVsByCode(v.child);
      }
    }
  });
}
Object.assign(VsModule.prototype, {
  child: [],
  model: 1,
  expand: true,
  id: null,
  name: '',
  sort: null
});
VsModule.prototype.initialize = function (sources) {
  for (var i = 0; i < sources.length; ++i) {
    var source = sources[i];
    for (var prop in source) {
      if (Object.prototype.hasOwnProperty.call(source, prop)) {
        if (source[prop] === undefined) {
          delete this[prop];
        } else {
          this[prop] = source[prop];
        }
      }
    }
  }
  if (this.child && this.child.length) {
    this.child = this.child.map(function (v) {
      return new VsGroup(v);
    });
  } else {
    this.child = [new VsGroup({
      child: [],
      expand: false,
      id: null,
      name: '',
      sort: null
    })];
  }
  return this;
};
VsModule.prototype.isTemp = function () {
  return this.tempsta === 1;
};

// 校验模块下是否有特殊的变量。存在的话禁止操作
VsModule.prototype.validHasSpecialCode = function () {
  specialCodeArr = [];
  getVsByCode(this.child);
  return specialCodeArr.some(function (v) {
    return SPECIAL_CODE.includes(v);
  });
};