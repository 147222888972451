function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import moment from 'moment';
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (typeof time === 'undefined' || time === null || time === 'null') {
    return '';
  } else if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} flag
 * @return {*}
 */
export function debounce(func, wait, flag) {
  var timer, args, that;
  return function () {
    // args包含了func的事件对象,that为func的this指向(应当指向事件源)
    args = arguments;
    that = this;
    var callnow = flag && !timer;
    if (callnow) func.apply(that, args); // 不传入参数flag这段行代码不执行
    clearTimeout(timer);
    timer = setTimeout(function () {
      timer = null;
      if (!flag) func.apply(that, args);
    }, wait);
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

// 替换邮箱字符
export function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    var str = email.split('@');
    var _s = '';
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*';
      }
    }
    var new_email = str[0].substr(0, 3) + _s + '@' + str[1];
  }
  return new_email;
}

// 替换手机字符
export function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7);
  }
  return new_mobile;
}

// 下载文件
export function downloadFile(obj, name, suffix) {
  var url = window.URL.createObjectURL(new Blob([obj]));
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  var fileName = parseTime(new Date()) + '-' + name + '.' + suffix;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function downloadExcell(obj, name, suffix) {
  var url = window.URL.createObjectURL(new Blob([obj], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }));
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  var fileName = name + '[' + moment(new Date()).format('YYYYMMDDHHmmss') + ']' + '.' + suffix;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function isMobile() {
  var isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!isMobile;
}
export function hasArrayDuplicates(arr) {
  return arr.length !== new Set(arr).size;
}
export function getUUID() {
  var unique = 0;
  var time = Date.now();
  var random = Math.floor(Math.random() * 10000);
  unique++;
  return random + unique + String(time);
}

/**
 * 获取URL参数
 * @param url
 * @returns {T | {}}
 */
export function getUrlParameters() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
  if (url.indexOf('?') < 0) return {};
  var arr = url.split('?');
  var params = arr[1].split('&');
  var obj = {};
  for (var i = 0; i < params.length; i++) {
    var _param = params[i].split('=');
    obj[_param[0]] = _param[1];
  }
  return obj;
}
export var object2tv = function object2tv(object, intValue) {
  var isSort = object['_sort'];
  var objectKeys = isSort ? isSort.split(',') : Object.keys(object);
  return objectKeys.map(function (key) {
    return {
      label: object[key],
      value: intValue ? +key : key
    };
  });
};
export var object2tn = function object2tn(object) {
  var isSort = object['_sort'];
  var objectKeys = isSort ? isSort.split(',') : Object.keys(object);
  return objectKeys.map(function (key) {
    return {
      label: object[key],
      value: Number(key)
    };
  });
};
export function getParentDom(element, className) {
  // dom.getAttribute('class')==dom.className，两者等价
  var returnParentElement = null;
  function getpNode(element, className) {
    if (element.parentNode === document) {
      return returnParentElement;
    }
    // 创建父级节点的类数组
    var pClassList = element.parentNode.getAttribute('class');
    var pNode = element.parentNode;
    if (!pClassList) {
      // 如果未找到类名数组，就是父类无类名，则再次递归
      getpNode(pNode, className);
    } else if (pClassList && pClassList.indexOf(className) < 0) {
      // 如果父类的类名中没有预期类名，则再次递归
      getpNode(pNode, className);
    } else if (pClassList && pClassList.indexOf(className) > -1) {
      returnParentElement = pNode;
    }
  }
  getpNode(element, className);
  return returnParentElement;
}
export function clearParams(obj) {
  var res = {};
  Object.keys(obj).forEach(function (k) {
    if (obj[k]) {
      res[k] = obj[k];
    }
  });
  return res;
}
export function formatCNDate(dateStr) {
  if (!dateStr) return '';
  var temp = moment(new Date(dateStr)).format('YYYY-MM-DD').split('-');
  return "".concat(temp[0], "\u5E74").concat(parseInt(temp[1]), "\u6708").concat(parseInt(temp[2]), "\u65E5");
}
export function _throttle(func, delay) {
  var timer = null;
  var startTime = Date.now();
  return function () {
    var curTime = Date.now();
    var remaining = delay - (curTime - startTime);
    var context = this;
    var args = arguments;
    console.log('context,', context);
    clearTimeout(timer);
    if (remaining <= 0) {
      console.log('time1---,', context);
      func.apply(context, args);
      startTime = Date.now();
    } else {
      console.log('time2---,', context);
      // timer = setTimeout(func, remaining);
    }
  };
}

export function flatten(arr) {
  return arr.reduce(function (pre, cur) {
    return pre.concat(Array.isArray(cur) ? flatten(cur) : cur);
  }, []);
}
export function getSelectBack(labelVal, selectList) {
  return labelVal.map(function (value, index) {
    var arr = selectList.filter(function (item) {
      return value === item.name;
    });
    var color = arr.length > 0 ? arr[0].color : '#333';
    return ".tagStyle .el-tag:nth-child(".concat(index + 1, ") {color: ").concat(color, ";border: 1px solid ").concat(color, ";background:").concat(hexToRgba(color, 0.2), "} .tagStyle .el-tag:nth-child(").concat(index + 1, ").el-tag--info .el-tag__close{color: ").concat(color, ";background:none}");
  }).join('');
}
// rgba
export function hexToRgba(hex, opacity) {
  if (!hex) hex = '#ededed';
  var rgba = 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + (opacity || '1') + ')';
  return rgba;
}
export function countStrWidth(text) {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  // 设置字体样式，当然，也可以在这里给一个默认值
  context.font = '14px Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif';
  var dimension = context.measureText(text);
  return dimension.width;
}
export function flexWidth(key, value, list) {
  var width = 0;
  var minWidth = 0;
  var arrNum = [];
  list = list || [];
  list.forEach(function (item) {
    if (key === 'PatientTags' || key === 'patientTagList') {
      var numlength = 0;
      item[key].slice(0, 3).forEach(function (i) {
        console.log('i', i.name);
        if (countStrWidth(i.name) > 80) {
          numlength += 80;
        } else {
          numlength += countStrWidth(i.name) + 20;
        }
      });
      if (window.innerWidth > 1800 && item[key].length > 3) {
        numlength += 30;
      } else {
        numlength -= 20;
      }
      arrNum.push(numlength);
    } else {
      arrNum.push(countStrWidth(item[key]));
    }
    // arrNum.push(countStrWidth(item[key]))
  });

  width = Math.max.apply(null, arrNum) + 15;
  minWidth = countStrWidth(value) + 30;
  if (width < minWidth) {
    return minWidth;
  } else {
    if (window.innerWidth < 1700 && window.innerWidth > 1580) {
      return width + 30;
    }
    return width;
  }
}
export function getdiseaseIcon(name) {
  name = name.replaceAll('INCREASE', '').replaceAll('标准研究', '');
  if (name === '肺癌') {
    return require('@/assets/images/database1.png');
  } else if (name === '结直肠癌') {
    return require('@/assets/images/database2.png');
  } else if (name === '胃癌') {
    return require('@/assets/images/database3.png');
  } else if (name === '食管癌') {
    return require('@/assets/images/database4.png');
  } else if (name === '肝癌') {
    return require('@/assets/images/database5.png');
  } else if (name === '纵隔肿瘤') {
    return require('@/assets/images/database6.png');
  } else if (name === '减重与代谢外科') {
    return require('@/assets/images/database7.png');
  } else {
    return require('@/assets/images/database.png');
  }
}
export function getNkReturn(varValue) {
  return varValue === 'NK' || varValue === 'NA' || varValue === 'ND';
}