// type 1 svg 2 png
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'svg'
    },
    title: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      type = _context$props.type,
      title = _context$props.title,
      active = _context$props.active;
    var vnodes = [];
    try {
      if (icon) {
        if (type === 'png') {
          var png = require("../../../assets/icons/png/".concat(icon).concat(active ? 'Active' : '', ".png"));
          vnodes.push(h("img", {
            "attrs": {
              "src": png
            },
            "class": 'item-icon'
          }));
        } else {
          vnodes.push(h("svg-icon", {
            "attrs": {
              "icon-class": icon
            }
          }));
        }
      }
      if (title) {
        vnodes.push(h("span", {
          "slot": 'title'
        }, [title]));
      }
      /* vnodes.push(<span class='el-icon-arrow-right arrow-ico'></span>) */
      return vnodes;
    } catch (e) {
      console.log(e);
      return vnodes;
    }
  }
};