import request from '@/utils/request';
export function accountLogin(data) {
  return request({
    url: '/api/web/user/login',
    method: 'post',
    data: data
  });
}
export function phoneLogin(data) {
  return request({
    url: '/api/web/user/phoneLogin',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: '/api/web/user/myDetail',
    method: 'get'
  });
}

// export function getCodeImg() {
//   return request({
//     url: 'auth/code',
//     method: 'get'
//   })
// }
export function getCodeImg() {
  return request({
    url: '/api/web/captcha/getCode',
    method: 'get'
  });
}
export function validatedCaptcha(data) {
  return request({
    url: '/api/web/captcha/validatedCaptcha',
    method: 'post',
    data: data
  });
}
export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  });
}
export function loginCode(phone) {
  return request({
    url: "/api/web/allow/loginCode?phone=".concat(phone),
    method: 'get'
  });
}
export function forgetCode(phone) {
  return request({
    url: "/api/web/allow/findPasswordCode?phone=".concat(phone),
    method: 'get'
  });
}
export function findPasswordEmailCode(email) {
  return request({
    url: "/api/web/allow/findPasswordEmailCode?email=".concat(email),
    method: 'get'
  });
}
export function validForgetPwd(data) {
  return request({
    url: "/api/web/user/validatedPwdChangeCode",
    method: 'post',
    data: data
  });
}
export function validatedPwdChangeEmailCode(data) {
  return request({
    url: "/api/web/user/validatedPwdChangeEmailCode",
    method: 'post',
    data: data
  });
}
export function resetPwd(data) {
  return request({
    url: "/api/web/user/resetPwd",
    method: 'post',
    data: data
  });
}