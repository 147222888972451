import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../layout/index';
import { itemStudyPage } from './page';
Vue.use(Router);
var baseRouterMap = [{
  path: '/register',
  meta: {
    title: '注册',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/register'], resolve);
  },
  hidden: true
}, {
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/research/list',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    hidden: true,
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }, {
    path: 'article',
    component: function component(resolve) {
      return require(['@/views/article/index'], resolve);
    },
    name: 'ArticleIndex',
    hidden: true,
    meta: {
      title: 'INCREASE动态',
      static: true,
      full: true
    }
  }, {
    path: 'article/:id',
    component: function component(resolve) {
      return require(['@/views/article/detail'], resolve);
    },
    name: 'ArticleDetail',
    hidden: true,
    meta: {
      title: 'INCREASE动态详细',
      static: true,
      full: true
    }
  }, {
    path: 'help',
    component: function component(resolve) {
      return require(['@/views/help/index'], resolve);
    },
    name: 'HelpIndex',
    hidden: true,
    meta: {
      title: '操作指南',
      static: true,
      full: true
    }
  }, {
    path: 'about',
    component: function component(resolve) {
      return require(['@/views/about/index'], resolve);
    },
    name: 'HelpIndex',
    hidden: true,
    meta: {
      title: '关于INCREASE',
      static: true,
      full: true
    }
  }, {
    path: 'siteSecurity',
    component: function component(resolve) {
      return require(['@/views/site/security'], resolve);
    },
    name: 'siteSecurity',
    hidden: true,
    meta: {
      title: '安全概述',
      static: true,
      full: true
    }
  }, {
    path: 'sitePrivacy',
    component: function component(resolve) {
      return require(['@/views/site/privacy'], resolve);
    },
    name: 'sitePrivacy',
    hidden: true,
    meta: {
      title: '隐私概述',
      static: true,
      full: true
    }
  }, {
    path: 'siteInfoLegal',
    component: function component(resolve) {
      return require(['@/views/site/infoLegal'], resolve);
    },
    name: 'siteInfoLegal',
    hidden: true,
    meta: {
      title: '法律条款',
      static: true,
      full: true
    }
  }, {
    path: 'agreement',
    component: function component(resolve) {
      return require(['@/views/site/agreement'], resolve);
    },
    name: 'agreement',
    hidden: true,
    meta: {
      title: '用户协议',
      static: true,
      full: true
    }
  }]
}, {
  path: '/research',
  component: Layout,
  name: 'ResearchMain',
  hidden: true,
  redirect: '/research/list',
  meta: {
    title: '研究列表',
    noCache: true,
    icon: 'researchList',
    iconType: 'png',
    zIndex: 1
  },
  children: [{
    path: 'list',
    component: function component(resolve) {
      return require(['@/views/research/list'], resolve);
    },
    name: 'ResearchList',
    meta: {
      title: '研究列表',
      noCache: true,
      icon: 'researchList',
      iconType: 'png'
    }
  }, {
    path: 'newCustom',
    component: function component(resolve) {
      return require(['@/views/research/newCustom/index'], resolve);
    },
    name: 'NewCustom',
    meta: {
      title: '新建自定义研究',
      noCache: true
    }
  }, {
    path: 'patientMng',
    component: function component(resolve) {
      return require(['@/views/research/patientMng'], resolve);
    },
    name: 'PatientMng',
    meta: {
      title: '患者管理',
      noCache: true
    }
  }, {
    path: 'preview',
    component: function component(resolve) {
      return require(['@/views/research/preview'], resolve);
    },
    name: 'Preview',
    meta: {
      title: '标准研究预览',
      noCache: true
    }
  }]
},
// {
//   path: '/coordinateCenter',
//   component: Layout,
//   meta: { title: '协调中心', icon: 'coordinateCenter', iconType: 'png', zIndex: 1 },
//   children: [
//     {
//       path: '/coordinateCenter',
//       component: (resolve) => require(['@/views/coordinateCenter/index'], resolve),
//       name: 'CoordinateCenter',
//       hidden: true,
//       meta: { title: '协调中心', noCache: true, icon: 'coordinateCenter', iconType: 'png' }
//     }
//   ]
// },
{
  path: 'help',
  component: function component(resolve) {
    return require(['@/views/help/index'], resolve);
  },
  meta: {
    title: '操作指南',
    icon: 'helpCenter',
    iconType: 'png',
    zIndex: 1
  }
}, {
  path: '/userCenter',
  component: Layout,
  meta: {
    zIndex: 1
  },
  children: [{
    path: '/userCenter',
    component: function component(resolve) {
      return require(['@/views/userCenter/index'], resolve);
    },
    name: 'UserCenter',
    meta: {
      title: '个人中心',
      noCache: true,
      icon: 'userCenter',
      iconType: 'png'
    }
  }]
}, {
  path: '/integralManage',
  component: Layout,
  meta: {
    zIndex: 1
  },
  children: [{
    path: '/integralManage',
    component: function component(resolve) {
      return require(['@/views/integralManage/index'], resolve);
    },
    name: 'UserCenter',
    meta: {
      title: '积分中心',
      noCache: true,
      icon: 'integral',
      iconType: 'png'
    }
  }]
}, {
  path: '/footprint',
  component: Layout,
  meta: {
    zIndex: 1
  },
  children: [{
    path: '/footprint',
    component: function component(resolve) {
      return require(['@/views/footprint/index'], resolve);
    },
    name: 'UserCenter',
    meta: {
      title: '我的足迹',
      noCache: true,
      icon: 'footprint',
      iconType: 'png'
    }
  }]
}
// {
//   path: '/user',
//   component: Layout,
//   hidden: true,
//   redirect: 'noredirect',
//   children: [
//     {
//       path: 'center',
//       component: (resolve) => require(['@/views/system/user/center'], resolve),
//       name: '个人中心',
//       meta: { title: '个人中心' }
//     }
//   ]
// }
];

export var constantRouterMap = baseRouterMap.concat(itemStudyPage);
export default new Router({
  // mode: 'hash',
  mode: 'history',
  // base: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_URL : '',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});