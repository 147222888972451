import { render, staticRenderFns } from "./CoreType.vue?vue&type=template&id=fcf6437c&scoped=true&"
import script from "./CoreType.vue?vue&type=script&lang=js&"
export * from "./CoreType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcf6437c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/increase-uat-frontend-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fcf6437c')) {
      api.createRecord('fcf6437c', component.options)
    } else {
      api.reload('fcf6437c', component.options)
    }
    module.hot.accept("./CoreType.vue?vue&type=template&id=fcf6437c&scoped=true&", function () {
      api.rerender('fcf6437c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/coreForm/item/lib/CoreType.vue"
export default component.exports