function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { EXCLUSIVE_RULE_OPT, VAR_TRIGGER_OPT, VAR_TRIGGER_LOGIC_JS, VAR_TRIGGER_RELATION_JS, VAR_TRIGGER_RELATION_RANGE } from '../enum/form';
import { getUUID, getParentDom } from '@/utils';
import _ from 'lodash';
import { VsModule } from './vsModule';
/**
 * 变量类
 * @returns {*}
 * @constructor
 */
export function VsField() {
  if (!(this instanceof VsField)) {
    return new VsField().initialize(arguments);
  } else if (arguments.length) {
    this.initialize(arguments);
  }
}
function jsEval(fn) {
  var Fn = Function;
  return new Fn('return ' + fn)();
}
function getParentModule(target) {
  var module = null;
  while (target._parent && target._parent instanceof VsModule) {
    module = target._parent;
  }
  return module;
}
function validGroupType(obj) {
  return obj && obj.typeCode && obj.typeCode === 13;
}
function isSameGroupVs(a, b) {
  // 非变量组 改成 变量组
  if (a.multiple && !validGroupType(a._parent)) {
    return b._parent && validGroupType(b._parent) && a.varGroupName && b._parent.name === a.varGroupName;
  }
  return a._parent && b._parent && a._parent.id === b._parent.id && validGroupType(a._parent) && validGroupType(b._parent);
}

// 获取dom-class类名 取得vsId
function getIdByClass(list) {
  var res = null;
  var idStr = Array.from(list).find(function (v) {
    return v.indexOf('field_') > -1;
  });
  if (idStr) {
    res = idStr.substring(6);
  }
  return res;
}
VsField.prototype.initialize = function (sources) {
  for (var i = 0; i < sources.length; ++i) {
    var source = sources[i];
    for (var prop in source) {
      if (Object.prototype.hasOwnProperty.call(source, prop)) {
        if (source[prop] === undefined) {
          delete this[prop];
        } else {
          this[prop] = source[prop];
        }
      }
    }
    // this.dcm = 2
    // this.isNeed = 1
    // this.optSort = 1
    // this.opted = '11'
    // 添加变量组按钮
    this._addGroup = true;
    // 删除变量组按钮
    this._delGroup = false;
    // 加载标识，用户图片类型字段
    this._loading = false;
    if (!this.id) {
      this.id = '_' + getUUID();
    }
  }
  return this;
};
VsField.prototype.isNew = function () {
  return this.id === undefined || this.id === null || this.id === '' || String(this.id).indexOf('_') > -1;
};

/**
 * 是否是临时字段
 * @returns {boolean}
 */
VsField.prototype.isTemp = function () {
  return this.tempsta === 1;
};
VsField.prototype.isMultiValue = function () {
  return [6, 8, 12].includes(this.typeCode);
};

/**
 * 变量是否是数值变量
 * @returns {boolean}
 */
VsField.prototype.isNumberType = function (value) {
  return [1].includes(value || this.typeCode);
};

/**
 * 变量是否是分类变量
 * @returns {boolean}
 */
VsField.prototype.isOptionType = function (value) {
  return [5, 6, 7, 8].includes(value || this.typeCode);
};

/**
 * 变量是否是分类单值变量
 * @returns {boolean}
 */
VsField.prototype.isSingleOptionType = function (value) {
  return [5, 6, 7, 8].includes(value || this.typeCode);
};

/**
 * 变量是否是分类多值变量
 * @returns {boolean}
 */
VsField.prototype.isMultiOptionType = function (value) {
  return [6, 8].includes(value || this.typeCode);
};

/**
 * 变量是否是多值变量
 * @returns {boolean}
 */
VsField.prototype.isMultiValueType = function () {
  return [12].includes(this.typeCode);
};

/**
 * 变量是否是变量组
 * @returns {boolean}
 */
VsField.prototype.isGroupType = function () {
  return [13].includes(this.typeCode);
};

/**
 * 变量是否是基础变量
 * @returns {boolean}
 */
VsField.prototype.isBasicType = function () {
  return [1, 2, 3].includes(this.typeCode);
};

/**
 * 变量是否是文本、图文注释
 * @returns {boolean}
 */
VsField.prototype.isExplanNoteType = function () {
  return [9, 10].includes(this.typeCode);
};

/**
 * 变量可以设置长度的
 * @returns {boolean}
 */
VsField.prototype.isCanLenType = function () {
  return [1, 2, 3, 12].includes(this.typeCode);
};
VsField.prototype.valToArr = function (value) {
  var splitKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ';';
  return !value ? [] : Array.isArray(value) ? value : value.split(splitKey);
};
VsField.prototype.arrToVal = function (value) {
  var splitKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ';';
  return Array.isArray(value) ? value.join(splitKey) : value;
};

/**
 * 互斥规则->前端格式
 * @param value
 * @returns {*}
 */
VsField.prototype.formatExEntityRule = function (value) {
  if (!value) {
    return [_.cloneDeep(EXCLUSIVE_RULE_OPT)];
  } else {
    // 1/2|3;2/4
    return value.split(';').map(function (v) {
      // 1/2|3 或者 2|4
      var er = v.split('&-&');
      return {
        lr: er[0],
        rr: er[1] ? er[1].split('|') : []
      };
    });
  }
};

/**
 * 互斥规则->后台格式
 * @param value
 * @returns {*}
 */
VsField.prototype.formatExResRule = function (value) {
  if (!value) {
    return null;
  } else {
    if (!Array.isArray(value)) {
      return value;
    }
    return value.map(function (v) {
      return v.lr + '&-&' + v.rr.join('|');
    }).filter(function (c) {
      return c !== '&-&';
    }).join(';');
  }
};

/**
 * 判断字段是否存在互斥选项
 * @param value
 * @returns {*}
 */
VsField.prototype.getExRuleByCheck = function (value) {
  var _this = this;
  if (!value || !Array.isArray(value) || value.length <= 1) {
    return [];
  }
  var conflict = [];
  var _loop = function _loop(ex) {
    var selectIndex = [];
    _this.exclusiveRule.forEach(function (c, n) {
      if (c.lr === value[ex]) {
        selectIndex.push(n);
      }
    });
    var _loop2 = function _loop2() {
      var other = value.filter(function (k) {
        return k !== value[ex];
      });
      var _loop3 = function _loop3(i) {
        var rex = _this.exclusiveRule[selectIndex[k]].rr.some(function (v) {
          return other[i] === v;
        });
        if (rex) {
          conflict.push(_this.exclusiveRule[selectIndex[k]]);
          return 1; // break
        }
      };
      for (var i = 0; i < other.length; i++) {
        if (_loop3(i)) break;
      }
    };
    for (var k = 0; k < selectIndex.length; k++) {
      _loop2();
    }
  };
  for (var ex = 0; ex < value.length; ex++) {
    _loop(ex);
  }
  return conflict;
};

/**
 * 核心方法 多条件字段是否显示
 * @returns {*}
 */
VsField.prototype.getCalcVisible = function () {
  var _this2 = this;
  var isNkNum = function isNkNum(val) {
    return String(val).indexOf('NK') > -1;
  };
  var toBool = function toBool(str) {
    return str === '' ? false : str;
  };
  var isEmpty = function isEmpty(val) {
    return val === '' || val === null || val === undefined || Array.isArray(val) && !val.length;
  };
  var visible = true;
  // (js >= 10) && (nc <= 20)
  if (this.showCondition !== 2) {
    return visible;
  } else {
    var varCalcStr = ''; // () && () || ()
    var allDependDomVisible = true;
    this.varTriggerDTOS.forEach(function (v, i) {
      var logic = i > 0 ? VAR_TRIGGER_LOGIC_JS[v.logic] : '';
      var relates = VAR_TRIGGER_RELATION_JS[v.relates];
      var modelVsKeys = window.$store.state.form.modelVsKeys;
      // 数值
      if (_this2.isNumberType(v.varType)) {
        // 特殊处理 数值 NK
        if (isNkNum(modelVsKeys[v.varId])) {
          varCalcStr = 'false';
          return;
        }
        var matchStr = '';
        // 避免空数据报错
        if (!isEmpty(modelVsKeys[v.varId])) {
          // 数组格式默认取第一个
          var selectVal = Array.isArray(modelVsKeys[v.varId]) ? modelVsKeys[v.varId][0] : modelVsKeys[v.varId];
          if (relates === VAR_TRIGGER_RELATION_RANGE) {
            var temA = relates.replace(/a/g, selectVal);
            var temB = temA.replace(/b/g, Number(v.opts[0]));
            var temC = temB.replace(/c/g, Number(v.opts[1]));
            matchStr += '(' + temC + ')';
          } else {
            matchStr += '(' + selectVal + relates + Number(v.opts) + ')';
          }
        }
        varCalcStr += logic + '' + toBool(matchStr);
        // 分类选项
      } else if (_this2.isOptionType(v.varType)) {
        var _matchStr = '';
        if (!isEmpty(modelVsKeys[v.varId])) {
          var _selectVal = Array.isArray(modelVsKeys[v.varId]) ? modelVsKeys[v.varId] : [modelVsKeys[v.varId]];
          // 满足任意一项
          if (v.showCondition === 1) {
            // varCalcStr = selectVal.some(v => v)
            _matchStr += '(' + v.opts.some(function (cv) {
              return _selectVal.includes(cv);
            }) + ')';
            // 同时满足
          } else if (v.showCondition === 2) {
            _matchStr += '(' + v.opts.every(function (cv) {
              return _selectVal.includes(cv);
            }) + ')';
          }
        }
        varCalcStr += logic + '' + toBool(_matchStr);
      }
      // 根据依赖的字段一同判断是否显示 A触发B B触发C A是B的依赖字段。 A不显示B就不显示 B不显示C也就不显示
      var dependVs = window.$store.state.form.researchFlatVs.find(function (c) {
        return c.id === v.varId;
      });
      if (dependVs && dependVs instanceof VsField) {
        var dependVisible = dependVs.getCalcVisible();
        if (!dependVisible) {
          allDependDomVisible = false;
        }
      }
    });
    console.log('核心是否显示条件', allDependDomVisible, varCalcStr);
    visible = allDependDomVisible && jsEval(String(varCalcStr));
    return visible;
  }
};
VsField.prototype.cloneEmpty = function () {
  var _this3 = this;
  if (!(this instanceof VsField)) {
    return;
  }
  var res = {};
  Object.keys(this).forEach(function (k) {
    res[k] = _.cloneDeep(_this3[k]);
  });
  return new VsField(res);
};
VsField.prototype.cloneObjectEntity = function () {
  var _this4 = this;
  var res = {};
  Object.keys(this).forEach(function (k) {
    res[k] = _.cloneDeep(_this4[k]);
  });
  return res;
};
VsField.prototype.canValidDragVs = function () {
  var _this5 = this;
  var resError = '';
  // 校验规则
  function validTrigger(a, b) {
    var error = '';
    // 变量组字段
    var aOutTriggerBInner = b.showCondition === 2 && b['triggerVos'].some(function (v) {
      return v.varId === a.id;
    });
    if (!a.multiple) {
      if (b.multiple) {
        // a外 b内 排除a是b触发条件
        if (!aOutTriggerBInner) {
          error = '由于变量显示条件设置的限制，无法移动变量';
        }
      }
    } else {
      if (b.multiple && !isSameGroupVs(a, b)) {
        error = '由于变量显示条件设置的限制，无法移动变量';
      }
      // a内 b外 限制a是b触发条件
      if (aOutTriggerBInner && !b.multiple) {
        error = '由于变量显示条件设置的限制，无法移动变量';
      }
    }
    return error;
  }
  // 获取真实拖拽后的dom
  function getRealDom(dragDomArr) {
    var res = null;
    for (var i = 0; i < dragDomArr.length; i++) {
      var list = dragDomArr[i].classList;
      if (Array.from(list).every(function (v) {
        return v.indexOf('v-leave') === -1;
      })) {
        res = dragDomArr[i];
        break;
      }
    }
    return res;
  }
  // console.log('拖拽对象', event, this)
  // const dragObj = event.draggedContext.element
  // const relateObj = event.relatedContext.element
  var targetArr = window.$store.state.form.researchFlatVs || [];
  var targetKey = {};
  targetArr.forEach(function (v) {
    targetKey[v.id] = v;
  });
  // 汇总拖拽字段可能涉及到关系的字段
  var relateArr = [];
  try {
    var dragDomArr = document.querySelectorAll(".field_".concat(this.id));
    var dragDom = getRealDom(dragDomArr);
    if (dragDom) {
      var parentDom = getParentDom(dragDom, 'groupVs');
      if (parentDom) {
        // 变量组内嵌套变量组
        if (parentDom && this.isGroupType()) {
          return '变量组不能嵌套限制，无法移动变量';
        }
        var pid = getIdByClass(parentDom.classList);
        this.multiple = 1;
        if (pid && targetKey[pid]) {
          this._parent = targetKey[pid];
        }
      } else {
        this.multiple = 0;
      }
    }

    // 当前字段存在触发规则
    if (this.showCondition === 2 && this['triggerVos'] && this['triggerVos'].length) {
      var _targetTrigger = this['triggerVos'].map(function (v) {
        return targetKey[v.varId];
      });
      relateArr = relateArr.concat(_targetTrigger);
    }
    // 当前字段被作为触发规则
    var targetVs = targetArr.filter(function (v) {
      return v instanceof VsField;
    });
    var targetTrigger = targetVs.filter(function (v) {
      return v['triggerVos'] && v['triggerVos'].some(function (v) {
        return v.varId === _this5.id;
      });
    });
    if (targetTrigger.length) {
      relateArr = relateArr.concat(targetTrigger);
    }
    for (var i = 0; i < relateArr.length; i++) {
      var test = validTrigger(this, relateArr[i]);
      if (test) {
        resError = test;
        return resError;
      }
    }
    var flowCode = window.$store.state.form.researchInfo.flowCode;
    // 判断当前变量是否是保存过的，保存过的变量不能拖动到变量组中。
    if (this.multiple === 1 && !this.tempsta && flowCode === 4 && !this.varGroup) {
      return '当前变量不得拖动到变量组中';
    }
    if (this._parent.typeCode === 13 && this.multiple === 0 && !this.tempsta && flowCode === 4) {
      return '当前变量不得拖动出变量组外';
    }
    if (this._parent.typeCode === 13 && this.multiple === 1 && !this.tempsta && flowCode === 4 && this.varGroup !== this._parent.id) {
      return '当前组内变量不得拖动到另外变量组内';
    }
  } catch (e) {
    console.log(e);
    return resError;
  }
  return resError;
};

/**
 * 可添加多条记录校验触发规则是否正确
 * 1.变量组内字段不能触发独立变量
 * 2.变量组不能作为触发条件
 * 3.变量组内变量只能由兄弟变量触发
 */
VsField.prototype.multiValidTrigger = function () {
  var _this6 = this;
  // 判断两个变量是否是用一个变量组
  var isSameGroupVs = function isSameGroupVs(a, b) {
    // 非变量组 改成 变量组
    if (a.multiple && !validGroupType(a._parent)) {
      return b._parent && validGroupType(b._parent) && a.varGroupName && b._parent.name === a.varGroupName;
    }
    return a._parent && b._parent && a._parent.id === b._parent.id && validGroupType(a._parent) && validGroupType(b._parent);
  };
  var vsName = function vsName(a) {
    return "".concat(a.varName) || '';
  };
  var error = '';
  if (this.showCondition === 1 || !this.varTriggerDTOS || !this.varTriggerDTOS.length) return error;
  try {
    // VS【非变量组字段】
    if (!this.multiple) {
      this.varTriggerDTOS.forEach(function (v) {
        var vs = !v.varId ? null : window.$store.state.form.researchFlatVs.find(function (c) {
          return c.id === v.varId;
        });
        if (vs) {
          v.multiple = vs.multiple;
          // 1.变量组内字段不能触发独立变量
          if (v.multiple) {
            throw new Error("\u663E\u793A\u6761\u4EF6\u4E2D\u7684\u53D8\u91CF".concat(vsName(_this6), " ").concat(vsName(vs), "\u65E0\u6CD5\u4F5C\u4E3A\u672C\u53D8\u91CF\u7684\u89E6\u53D1\u663E\u793A\u6761\u4EF6"));
          }
          // 2.变量组不能作为触发条件-触发列表已过滤变量组 暂时没有场景
          if (validGroupType(v)) {
            throw new Error("\u663E\u793A\u6761\u4EF6\u4E2D\u7684\u53D8\u91CF".concat(vsName(_this6), " ").concat(vsName(vs), "\u65E0\u6CD5\u4F5C\u4E3A\u672C\u53D8\u91CF\u7684\u89E6\u53D1\u663E\u793A\u6761\u4EF6"));
          }
        }
      });
      // VS【变量组字段】
    } else {
      this.varTriggerDTOS.forEach(function (v) {
        var vs = !v.varId ? null : window.$store.state.form.researchFlatVs.find(function (c) {
          return c.id === v.varId;
        });
        if (vs) {
          v.multiple = vs.multiple;
          // 2.变量组不能作为触发条件-触发列表已过滤变量组 暂时没有场景
          if (validGroupType(v)) {
            throw new Error("\u663E\u793A\u6761\u4EF6\u4E2D\u7684\u53D8\u91CF".concat(vsName(_this6), " ").concat(vsName(vs), "\u65E0\u6CD5\u4F5C\u4E3A\u672C\u53D8\u91CF\u7684\u89E6\u53D1\u663E\u793A\u6761\u4EF6"));
          }
          // 3.变量组组内变量只能由兄弟变量触发
          if (v.multiple && !isSameGroupVs(_this6, vs)) {
            throw new Error("\u663E\u793A\u6761\u4EF6\u4E2D\u7684\u53D8\u91CF".concat(vsName(_this6), " ").concat(vsName(vs), "\u65E0\u6CD5\u4F5C\u4E3A\u672C\u53D8\u91CF\u7684\u89E6\u53D1\u663E\u793A\u6761\u4EF6"));
          }
        }
      });
    }
  } catch (e) {
    var msg = String(e).replace(/Error: /g, '');
    error = msg;
  }
  return error;
};

/**
 * 后端变量字段转成系统实体类
 * 回显
 * @returns {VsField}
 */
VsField.prototype.resTransToEntity = function () {
  var _this7 = this;
  this.units = !this.valToArr(this.units).length ? [''] : this.valToArr(this.units);
  // 处理分类变量-选项
  if (this.opts && this.opts.length) {
    this.opts.forEach(function (v) {
      v.additional = !!v.additional;
    });
  }
  // this.picUrl = this.valToArr(this.picUrl)
  this.varOpts = this.opts || [];
  this.varName = this.name || '';
  this.maxValue = this.maxVal;
  this.minValue = this.minVal;
  // 显示条件
  if (this.showCondition === 2) {
    this.varTriggerDTOS = !this.triggerVos ? [_.cloneDeep(VAR_TRIGGER_OPT)] : this.triggerVos.map(function (v) {
      return _objectSpread(_objectSpread({}, v), {}, {
        opts: _this7.valToArr(v.opts).map(function (c) {
          return c;
        })
      });
    });
  } else {
    this.varTriggerDTOS = [_.cloneDeep(VAR_TRIGGER_OPT)];
  }
  if (this.isExplanNoteType()) {
    if (this.opted && !this.comment) {
      this.comment = this.opted;
    }
  }
  // 质疑列表解析
  this.exclusiveRule = this.formatExEntityRule(this.exclusiveRule);
  return this;
};

/**
 * 系统实体类转后台变量
 * 保存
 * @returns {VsField}
 */
VsField.prototype.entityTransToRes = function () {
  var _this8 = this;
  var clone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  var _vsField = clone ? _.cloneDeep(this) : this;
  // function handleTriggle() {}
  _vsField.units = this.arrToVal(_vsField.units);
  _vsField.exclusiveRule = this.formatExResRule(_vsField.exclusiveRule) || null;
  // _vsField.picUrl = this.arrToVal(_vsField.picUrl)
  // 处理分类变量-选项
  if (_vsField.varOpts && _vsField.varOpts.length) {
    _vsField.varOpts.forEach(function (v) {
      v.additional = v.additional ? 1 : 0;
      if (!v.id) {
        delete v.id;
      }
    });
  }
  // 显示条件
  if (_vsField.varTriggerDTOS && _vsField.varTriggerDTOS.length) {
    var realTrigger = _vsField.varTriggerDTOS.filter(function (v) {
      return v.varId;
    });
    if (!realTrigger.length) {
      _vsField.varTriggerDTOS = null;
    } else {
      _vsField.varTriggerDTOS.forEach(function (v) {
        v.opts = _this8.arrToVal(v.opts);
      });
    }
  }
  if (_vsField.showCondition === 1) {
    _vsField.varTriggerDTOS = null;
  }
  if (!_vsField.multiple) {
    _vsField.varGroup = '';
  }
  // 非选项类型删除选项数据
  if (!_vsField.isOptionType()) {
    _vsField.varOpts = [];
  }
  if (clone) {
    if (_vsField.isNew()) {
      delete _vsField.id;
    }
    delete _vsField._addGroup;
    delete _vsField._delGroup;
    delete _vsField._parent;
    // 无需设置变量长度的字段。还原成null
    if (!this.isCanLenType()) {
      _vsField.length = null;
    }
    return _vsField;
  } else {
    return this;
  }
};

// 校验变量是否是否
VsField.prototype.moduleClassification = function () {
  var module = getParentModule(this);
  if (!module) {
    module = window.$store.state.form.selectModule;
  }
  return ['1', '2', '3'].includes(String(module.classification));
};