import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';

// 数据字典
import dict from './components/Dict';

// 权限指令
import checkPer from '@/utils/permission';
import permission from './components/Permission';
import './assets/styles/element-variables.scss';

// global css
import './assets/styles/index.scss';
import { getdiseaseIcon, getNkReturn } from '@/utils/index';
import App from './App';
import store from './store';
import router from './router/routers';
import { Message } from 'element-ui';
import './assets/icons'; // icon
import './router/index'; // permission control
import './utils/rem';
import { appConfig } from './config';
Element.Dialog.props.closeOnClickModal.default = false;
// 核心全局表单字段
import formCoreFields from '@/views/components/coreForm/item/lib/index';
window.$store = store;
window.$router = router;
window.$appConfig = appConfig;
Vue.prototype.getdiseaseIcon = getdiseaseIcon;
Vue.prototype.getNkReturn = getNkReturn;
Vue.use(formCoreFields);
Vue.use(checkPer);
Vue.use(permission);
Vue.use(dict);
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

Vue.config.productionTip = false;
// 注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$message = function (msg) {
  return Message({
    message: msg,
    duration: 6000,
    offset: 15
  });
};
// 分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Message.success({
    message: msg,
    duration: 6000,
    offset: 15
  });
};
Vue.prototype.$message.warning = function (msg) {
  return Message.warning({
    message: msg,
    duration: 6000,
    offset: 15
  });
};
Vue.prototype.$message.error = function (msg) {
  return Message.error({
    message: msg,
    duration: 6000,
    offset: 15
  });
};
window.$Vue = new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});