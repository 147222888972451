var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    attrs: {
      disabled: !_vm.formFieldEdit
    }
  }, [_c("div", {
    staticClass: "item-field horizontal"
  }, [_c("div", {
    staticClass: "label sw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "如需通过另一个选项型变量触发显示该变量，请在此处设置。否则系统将默认显示"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("显示条件"), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")])], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-select", {
    staticClass: "full-select",
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.change
    },
    model: {
      value: _vm.condition,
      callback: function callback($$v) {
        _vm.condition = $$v;
      },
      expression: "condition"
    }
  }, [_c("el-option", {
    attrs: {
      label: "始终显示",
      value: 1
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "其他变量触发显示",
      value: 2
    }
  })], 1)], 1), _vm._v(" "), _vm.condition === 2 ? _c("div", {
    staticClass: "control-list"
  }, _vm._l(_vm.control, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "visible-control"
    }, [index > 0 ? _c("div", {
      staticClass: "var-trigger-logic"
    }, [_c("el-select", {
      attrs: {
        placeholder: "请选择"
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.logic,
        callback: function callback($$v) {
          _vm.$set(item, "logic", $$v);
        },
        expression: "item.logic"
      }
    }, _vm._l(_vm.varLogic[index], function (op) {
      return _c("el-option", {
        key: op.value,
        attrs: {
          label: op.label,
          value: op.value
        }
      });
    }), 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "inner-control"
    }, [_c("div", {
      staticClass: "row-control"
    }, [_c("div", {
      staticClass: "title"
    }, [_vm._v("变量")]), _vm._v(" "), _c("tree-select", {
      attrs: {
        data: _vm.treeData,
        "tree-key": _vm.treeKey
      },
      on: {
        change: function change(obj) {
          return _vm.changeVarItem(obj, index);
        }
      },
      model: {
        value: item.varId,
        callback: function callback($$v) {
          _vm.$set(item, "varId", $$v);
        },
        expression: "item.varId"
      }
    }), _vm._v(" "), _vm.isFLType(item.varType) ? _c("div", {
      staticClass: "control-fl-box"
    }, [_c("el-select", {
      key: "fl-select-" + index,
      staticClass: "ts-child",
      attrs: {
        multiple: "",
        placeholder: "请选择"
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.opts,
        callback: function callback($$v) {
          _vm.$set(item, "opts", $$v);
        },
        expression: "item.opts"
      }
    }, _vm._l(_vm.childOpt[index], function (op) {
      return _c("el-option", {
        key: op.id || index,
        attrs: {
          label: op.content,
          value: op.serialNum
        }
      });
    }), 1), _vm._v(" "), _c("div", {
      staticClass: "inner-ls"
    }, [_c("el-select", {
      key: "condition-select-" + index,
      staticClass: "large-item",
      attrs: {
        placeholder: "请选择"
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.showCondition,
        callback: function callback($$v) {
          _vm.$set(item, "showCondition", $$v);
        },
        expression: "item.showCondition"
      }
    }, _vm._l(_vm.varCondition[index], function (op) {
      return _c("el-option", {
        key: op.value,
        attrs: {
          label: op.label,
          value: op.value
        }
      });
    }), 1), _vm._v(" "), _c("span", [_vm._v("时显示")])], 1)], 1) : _vm.isSZType(item.varType) ? _c("div", {
      staticClass: "control-sz-box"
    }, [_c("el-select", {
      key: "sz-select-" + index,
      staticClass: "min-item",
      attrs: {
        placeholder: "请选择"
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.relates,
        callback: function callback($$v) {
          _vm.$set(item, "relates", $$v);
        },
        expression: "item.relates"
      }
    }, _vm._l(_vm.varRelation[index], function (op) {
      return _c("el-option", {
        key: op.value,
        attrs: {
          label: op.label,
          value: op.value
        }
      });
    }), 1), _vm._v(" "), _c("span", [_vm._v("值")]), _vm._v(" "), item.relates === "7" ? _c("span", {
      staticClass: "range-num"
    }, [_c("el-input-number", {
      staticClass: "evey-min-item",
      attrs: {
        controls: false
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.opts[0],
        callback: function callback($$v) {
          _vm.$set(item.opts, 0, $$v);
        },
        expression: "item.opts[0]"
      }
    }), _vm._v(" "), _c("span", {
      staticClass: "mg-5"
    }, [_vm._v("至")]), _vm._v(" "), _c("el-input-number", {
      staticClass: "evey-min-item",
      attrs: {
        controls: false
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.opts[1],
        callback: function callback($$v) {
          _vm.$set(item.opts, 1, $$v);
        },
        expression: "item.opts[1]"
      }
    })], 1) : _c("el-input-number", {
      staticClass: "min-item",
      attrs: {
        controls: false
      },
      on: {
        change: _vm.change
      },
      model: {
        value: item.opts[0],
        callback: function callback($$v) {
          _vm.$set(item.opts, 0, $$v);
        },
        expression: "item.opts[0]"
      }
    }), _vm._v(" "), _c("span", [_vm._v("时显示")])], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
      staticClass: "var-trigger-action"
    }, [index === _vm.control.length - 1 ? _c("el-popover", {
      attrs: {
        placement: "top-start",
        width: "250",
        trigger: "hover"
      }
    }, [_c("div", {
      staticClass: "popover-tips"
    }, [_c("div", [_vm._v("如果您添加了超过2个条件，并且逻辑关系既有“且”又有“或”，结果以程序执行逻辑为准。")]), _vm._v(" "), _c("div", [_vm._v("请在预览页检查变量的显示方式，确保逻辑设置正确")])])]) : _vm._e(), _vm._v(" "), index > 0 && index === _vm.control.length - 1 ? _c("el-button", {
      staticClass: "del-opt",
      attrs: {
        type: "primary",
        size: "small"
      },
      on: {
        click: function click() {
          return _vm.delVarItem(index);
        }
      }
    }, [_vm._v("删除条件")]) : _vm._e()], 1)])]);
  }), 0) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };