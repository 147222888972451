var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_vm.showLogo ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c("user-card"), _vm._v(" "), _vm.permission_zindex === 2 ? _c("div", {
    staticClass: "return-back",
    on: {
      click: function click($event) {
        return _vm.$router.push({
          path: "/research/list"
        });
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-d-arrow-left"
  }), _vm._v("返回首页\n  ")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "sidebar-menu"
  }, [_c("el-scrollbar", {
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_vm.permission_zindex === 1 ? _c("ul", {
    staticClass: "el-menu rs-page-menu"
  }, [_c("div", {
    staticClass: "menu-wrapper"
  }, [_c("li", {
    staticClass: "menu-item",
    class: ["el-submenu", _vm.rsMenuActive ? "is-active" : "", _vm.rsPathActive ? "pane-active" : ""]
  }, [_c("router-link", {
    attrs: {
      to: _vm.rsPage.path
    }
  }, [_c("div", {
    staticClass: "el-submenu__title",
    staticStyle: {
      "padding-left": "20px",
      color: "#666s",
      display: "flex",
      "align-items": "center"
    }
  }, [_c("img", {
    staticClass: "item-icon",
    attrs: {
      src: _vm.rsIcon
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "name",
    attrs: {
      title: _vm.rsPage.title
    }
  }, [_vm._v(_vm._s(_vm.rsPage.title))])])]), _vm._v(" "), _c("ul", {
    staticClass: "el-menu el-menu--inline"
  }, [_c("div", {
    staticClass: "menu-wrapper nest-menu"
  }, _vm._l(_vm.rsPage.children, function (item) {
    return _c("li", {
      key: item.path,
      class: _vm.rsSubMenuActive(item.path)
    }, [item.icon ? _c("img", {
      staticClass: "item-icon",
      staticStyle: {
        display: "block"
      },
      attrs: {
        src: _vm.towIcon(item)
      }
    }) : _vm._e(), _vm._v(" "), _c("span", {
      attrs: {
        title: _vm.getSubMenuTitle(item)
      }
    }, [_vm._v(_vm._s(_vm.getSubMenuTitle(item)))])]);
  }), 0)])], 1)])]) : _vm._e(), _vm._v(" "), _c("el-menu", {
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: _vm.isCollapse,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "active-text-color": _vm.variables.menuActiveText,
      "collapse-transition": false,
      "unique-opened": "",
      mode: "vertical"
    }
  }, _vm._l(_vm.sidebarRouters, function (route) {
    return _c("sidebar-item", {
      key: route.path,
      attrs: {
        item: route,
        "base-path": route.path
      }
    });
  }), 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };