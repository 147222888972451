var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-field"
  }, [_c("div", {
    staticClass: "label sw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "变量名称在此病种库中是唯一的，同一研究内变量名称不能重复。不同研究之间，同样的变量名称表示两个变量是同一变量"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("变量名称"), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")]), _vm._v(" "), _vm.formSelectTriggerVs ? _c("el-popover", {
    staticClass: "warning-ico",
    attrs: {
      "popper-class": "vs-popover-error-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "此变量是其它变量的触发条件，不可修改变量类型和已有选项"
    }
  }, [_c("i", {
    staticClass: "warning-ico el-icon-warning",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入内容"
    },
    on: {
      input: function input($event) {
        return _vm.change();
      }
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "model"
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };