var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [!_vm.formClassification ? _c("div", {
    staticClass: "item-field horizontal"
  }, [_c("div", {
    staticClass: "label bw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "如勾选“是”，在模块及分组内可多次添加该变量"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("是否可添加多条记录"), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")])], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-radio-group", {
    on: {
      change: _vm.change
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("是")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 0
    }
  }, [_vm._v("否")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "more"
  }, [_vm.model === 1 ? _c("el-input", {
    staticClass: "full-input",
    attrs: {
      placeholder: "请输入变量组名称"
    },
    on: {
      blur: _vm.change
    },
    model: {
      value: _vm.groupName,
      callback: function callback($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  }) : _vm._e()], 1)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };