function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapGetters } from 'vuex';
import Logo from './Logo';
import UserCard from './UserCard';
import SidebarItem from './SidebarItem';
import _variables from '@/assets/styles/variables.scss';

// 跟路由无关
var asyncResearchPage = {
  path: '/research',
  title: '研究列表',
  children: [{
    path: '/research/newCustom',
    title: '新建自定义研究'
  }, {
    path: '/research/patientMng',
    title: '患者管理',
    icon: 'gzicon',
    iconType: 'png'
  }, {
    path: '/research/preview',
    title: '标准研究预览',
    icon: 'overview-icon',
    iconType: 'png'
  }]
};
export default {
  components: {
    UserCard: UserCard,
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      rsPage: asyncResearchPage
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['UserCard', 'sidebarRouters', 'sidebar', 'permission_zindex'])), {}, {
    rsIcon: function rsIcon() {
      return require("../../../assets/icons/png/researchList".concat(this.rsMenuActive ? 'Active' : '', ".png"));
    },
    rsPathActive: function rsPathActive() {
      return this.$route.path === '/research/list';
    },
    rsMenuActive: function rsMenuActive() {
      return this.rsPage.children.map(function (v) {
        return v.path;
      }).includes(this.$route.path) || this.rsPathActive;
    },
    rsSubMenuActive: function rsSubMenuActive() {
      return function (path) {
        return this.$route.path === path ? 'el-menu-item is-active pane-active show' : 'el-menu-item hide';
      };
    },
    getSubMenuTitle: function getSubMenuTitle() {
      return function (item) {
        var _this = this;
        var bzRecord = this.$store.state.user.researchCollect.find(function (v) {
          return String(v.id) === String(_this.$route.query.id);
        });
        return item.path === '/research/patientMng' ? bzRecord ? bzRecord.name + item.title : item.title : item.title;
      };
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      // return !this.sidebar.opened
      return false;
    }
  }),
  created: function created() {
    console.log(this.UserCard, this.sidebarRouters, this.sidebar, this.permission_zindex, '678');
  },
  methods: {
    towIcon: function towIcon(item) {
      console.log('iconiconiconiconicon', item);
      if (item.icon) {
        return require("../../../assets/icons/png/".concat(item.icon, ".png"));
      }
    }
  }
};