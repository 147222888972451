var rules = {
  state: {
    challengeRules: [],
    countRules: [],
    scrollTo: 0,
    ocrNum: 0,
    createData: '',
    questionIgnore: [],
    surgicalDate: '' // 手术日期
  },

  mutations: {
    CHALLENGE_RULES: function CHALLENGE_RULES(state, data) {
      state.challengeRules = data;
    },
    COUNT_RULES: function COUNT_RULES(state, data) {
      state.countRules = data;
    },
    SRCOLL_TO: function SRCOLL_TO(state, data) {
      state.scrollTo = data;
    },
    OCR_NUM: function OCR_NUM(state, data) {
      state.ocrNum = data;
    },
    CREATE_DATA: function CREATE_DATA(state, data) {
      state.createData = data;
    },
    QUESTION_IGNORE: function QUESTION_IGNORE(state, data) {
      state.questionIgnore = data;
    },
    SET_SURGICALDATE: function SET_SURGICALDATE(state, data) {
      state.surgicalDate = data;
    }
  }
};
export default rules;