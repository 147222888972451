var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-field horizontal"
  }, [_c("div", {
    staticClass: "label sw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "页面显示上传图片按钮"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("图片文件"), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")])], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "请上传图片"
    },
    on: {
      blur: _vm.setPicUrl
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }), _vm._v(" "), _c("el-form", {
    attrs: {
      disabled: _vm.formFieldEdit && _vm.formEditable ? false : true
    }
  }, [_c("el-upload", {
    staticClass: "upload-picture",
    attrs: {
      action: _vm.uploadImgUrl,
      accept: ".jpg,.jpeg,.png,.gif",
      "show-file-list": false,
      "file-list": _vm.fileList,
      "before-upload": _vm.handleUploadBefore,
      "on-success": _vm.handleUploadSuccess,
      headers: _vm.headers
    }
  }, [_c("el-button", {
    attrs: {
      loading: _vm.loading,
      size: "small",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.model && _vm.model.length ? "重新上传" : "上传"))])], 1)], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };