// rem等比适配配置文件
// 基准大小

// 设置 rem 函数
function setRem() {
  var body = document.getElementsByTagName('body')[0];
  var isPad = navigator.userAgent.indexOf('iPad') > -1;
  var isMobile = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('ios') > -1;
  var baseSize = isMobile || isPad ? 14 : 16;
  var baseWidth = isMobile ? 375 : isPad ? 1024 : 1920;
  // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  var scale = document.documentElement.clientWidth / baseWidth;
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize = parseInt(baseSize * Math.min(scale, 2)) + 'px';
  body.className = isMobile ? 'theme-mobile' : 'theme-pc';
  window.$IS_MOBILE = !!isMobile;
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};