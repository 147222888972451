var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    attrs: {
      disabled: _vm.formFieldEdit && _vm.formEditable ? false : true
    }
  }, [_c("div", {
    staticClass: "item-field horizontal"
  }, [_c("div", {
    staticClass: "label sw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "数值和文本变量的帮助文字，帮助文字显示在输入框里，输入后消失。选项和其他类型的变量，帮助文字始终显示在变量下方"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("变量提示\n    ")], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.value
    },
    on: {
      input: function input($event) {
        return _vm.change();
      }
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "model"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };