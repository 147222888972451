var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    attrs: {
      disabled: _vm.formFieldEdit && _vm.formEditable ? false : true
    }
  }, [_c("div", {
    staticClass: "item-field"
  }, [_c("div", {
    staticClass: "label sw"
  }, [_c("el-popover", {
    attrs: {
      "popper-class": "vs-popover-tips",
      placement: "top-start",
      width: "200",
      trigger: "hover",
      content: "光标放到变量旁边的问号图标上时，弹出tip显示变量的提示内容"
    }
  }, [_c("i", {
    staticClass: "el-icon-question",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })]), _vm._v("变量字典\n    ")], 1), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      placeholder: "请输入内容"
    },
    on: {
      blur: function blur(e) {
        return _vm.change(e.target.value);
      }
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "model"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };