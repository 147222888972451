/*
 * @Author: dihang dihang@hello-orange.com
 * @Date: 2023-03-08 09:47:40
 * @LastEditors: dihang dihang@hello-orange.com
 * @LastEditTime: 2023-03-16 09:35:27
 * @FilePath: \web\src\router\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from '../layout/index';

// 研究管理子菜单
var ItemStudySummary = {
  path: '/itemStudy/list',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/itemStudy/list',
    component: function component(resolve) {
      return require(['@/views/itemStudy/summary/index'], resolve);
    },
    name: 'ItemStudySummary',
    meta: {
      title: '研究概览',
      icon: 'ItemStudySummary',
      iconType: 'png',
      noCache: true
    }
  }]
};
var ItemStudyConfig = {
  path: '/itemStudy/config',
  component: Layout,
  name: 'CardItemStudyConfig',
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/itemStudy/config',
    component: function component(resolve) {
      return require(['@/views/itemStudy/config'], resolve);
    },
    meta: {
      title: '研究配置',
      noCache: true,
      show: false,
      permission: 'research_configuration'
    }
  }]
};
var ItemStudyChallenge = {
  path: '/itemStudy/challenge',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/itemStudy/challenge',
    component: function component(resolve) {
      return require(['@/views/itemStudy/challenge'], resolve);
    },
    name: 'ItemStudyChallenge',
    meta: {
      title: '质疑管理',
      icon: 'ItemStudyChallenge',
      iconType: 'png',
      noCache: true
    }
  }]
};

// const ItemStudyReport = {
//   path: '/itemStudy/report',
//   component: Layout,
//   meta: { zIndex: 2 },
//   hidden: true,
//   children: [
//     {
//       path: '/itemStudy/report',
//       component: (resolve) => require(['@/views/itemStudy/report'], resolve),
//       name: 'ItemStudyReport',
//       meta: { title: '研究报告', noCache: true, permission: 'show_research_report' }
//     }
//   ]
// }

// const ItemStudyDocument = {
//   path: '/itemStudy/document',
//   component: Layout,
//   meta: { zIndex: 2 },
//   hidden: true,
//   children: [
//     {
//       path: '/itemStudy/document',
//       component: (resolve) => require(['@/views/itemStudy/document'], resolve),
//       name: 'ItemStudyDocument',
//       meta: { title: '研究文档', noCache: true }
//     }]
// }

// 患者管理子菜单
var PatientManageList = {
  path: '/patientManage/list',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/patientManage/list',
    component: function component(resolve) {
      return require(['@/views/patientManage/list'], resolve);
    },
    name: 'PatientManageList',
    meta: {
      title: '患者列表',
      icon: 'PatientManageList',
      iconType: 'png',
      noCache: true
    }
  }]
};
var PatientManageDetail = {
  path: '/patientManage/detail',
  component: Layout,
  name: 'CardPatientManageDetail',
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/patientManage/detail',
    component: function component(resolve) {
      return require(['@/views/patientManage/detail'], resolve);
    },
    name: 'PatientManageDetail',
    meta: {
      title: '患者信息详情',
      noCache: true,
      show: false,
      icon: 'files3',
      iconType: 'png'
    }
  }]
};
var PatientManageFollowUp = {
  path: '/patientManage/followUp',
  component: Layout,
  name: 'CardPatientManageFollowUp',
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/patientManage/followUp',
    component: function component(resolve) {
      return require(['@/views/patientManage/followUp'], resolve);
    },
    name: 'PatientManageFollowUp',
    meta: {
      title: '随访管理',
      icon: 'PatientManageFollowUp',
      iconType: 'png',
      noCache: true
    }
  }]
};
var PatientManageQuery = {
  path: '/patientManage/query',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/patientManage/query',
    component: function component(resolve) {
      return require(['@/views/patientManage/query'], resolve);
    },
    name: 'PatientManageQuery',
    meta: {
      title: '质疑管理',
      icon: 'PatientManageQuery',
      iconType: 'png',
      noCache: true
    }
  }]
};

// const PatientManageInteract = {
//   path: '/patientManage/interact',
//   component: Layout,
//   meta: { zIndex: 2 },
//   hidden: true,
//   children: [
//     {
//       path: '/patientManage/interact',
//       component: (resolve) => require(['@/views/patientManage/interact'], resolve),
//       name: 'PatientManageInteract',
//       meta: { title: '患者互动TBD', noCache: true, permission: 'patient_interaction' }
//     }
//   ]
// }

var ItemStudyAnalysis = {
  path: '/itemStudy/analysis',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/itemStudy/analysis',
    component: function component(resolve) {
      return require(['@/views/itemStudy/analysis'], resolve);
    },
    name: 'ItemStudyAnalysis',
    meta: {
      title: '统计分析',
      icon: 'ItemStudyAnalysis',
      iconType: 'png',
      noCache: true
    }
  }]
};

// const PatientManageImport = {
//   path: 'import',
//   component: (resolve) => require(['@/views/patientManage/import'], resolve),
//   name: 'PatientManageImport',
//   hidden: false,
//   meta: { title: '导入患者', noCache: true }
// }

// 患者管理子菜单
var PersonManageList = {
  path: '/personManage/list',
  component: Layout,
  meta: {
    zIndex: 2
  },
  hidden: true,
  children: [{
    path: '/personManage/list',
    component: function component(resolve) {
      return require(['@/views/itemPerson/index'], resolve);
    },
    name: 'PersonManageList',
    meta: {
      title: '人员管理',
      noCache: true,
      icon: 'personManage',
      iconType: 'png'
    }
  }]
};

// export const itemStudyPage = [
//   {
//     path: '/itemStudy',
//     component: Layout,
//     name: 'ItemStudy',
//     hidden: true,
//     meta: { title: '研究管理', affix: true, noCache: true, zIndex: 2, icon: 'itemStudy', iconType: 'png' },
//     children: [
//       ItemStudySummary,
//       ItemStudyConfig,
//       ItemStudyChallenge,
//       ItemStudyReport,
//       ItemStudyDocument
//     ]
//   },
//   {
//     path: '/patientManage',
//     component: Layout,
//     name: 'PatientManage',
//     hidden: true,
//     meta: { title: '患者管理', affix: true, noCache: true, zIndex: 2, icon: 'patientManage', iconType: 'png' },
//     children: [
//       PatientManageList,
//       PatientManageDetail,
//       PatientManageFollowUp,
//       // PatientManageQuery,
//       PatientManageInteract
//       // PatientManageImport
//     ]
//   },
//   {
//     path: '/personManage',
//     component: Layout,
//     name: 'PersonManage',
//     hidden: true,
//     meta: { title: '人员管理', affix: true, noCache: true, zIndex: 2 },
//     children: [
//       PersonManageList
//     ]
//   }
// ]

export var itemStudyPage = [ItemStudySummary, PatientManageList, PatientManageDetail, PatientManageFollowUp,
// PatientManageInteract,
ItemStudyChallenge,
// ItemStudyReport,
// ItemStudyDocument,
ItemStudyAnalysis, ItemStudyConfig, PersonManageList];
export var ItemStudyAsyncChildMenu = {
  ItemStudySummary: ItemStudySummary,
  ItemStudyConfig: ItemStudyConfig,
  ItemStudyChallenge: ItemStudyChallenge,
  // ItemStudyReport,
  // ItemStudyDocument,
  PatientManageList: PatientManageList,
  PatientManageDetail: PatientManageDetail,
  PatientManageFollowUp: PatientManageFollowUp,
  PatientManageQuery: PatientManageQuery,
  // PatientManageInteract,
  // PatientManageImport,
  PersonManageList: PersonManageList
};