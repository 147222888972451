import request from '@/utils/request';
import qs from 'qs';

// 创建自定义研究
export function createResearch(data) {
  return request({
    url: '/api/web/system/research/save',
    method: 'post',
    data: data
  });
}
export function limitResearchNum(params) {
  return request({
    url: '/api/web/system/research/nums',
    method: 'get',
    params: params
  });
}

// 修改自定义研究基本信息
export function editResearch(data) {
  return request({
    url: '/api/web/system/research/update',
    method: 'post',
    data: data
  });
}

// 删除自定义研究
export function deleteResearch(data) {
  return request({
    url: '/api/web/system/research/delete',
    method: 'post',
    data: data
  });
}
export function getResearchList(params) {
  return request({
    url: '/api/web/system/research/my/list',
    method: 'get',
    params: params
  });
}

// 校验研究名称是否唯一
export function onlyReasechName(params) {
  return request({
    url: '/api/web/system/research/check/name',
    method: 'get',
    params: params
  });
}

// 根据ID查询研究基本信息
export function getResearchBasicById(params) {
  return request({
    url: '/api/web/system/research/info',
    method: 'get',
    params: params
  });
}
// 单个研究-新增研究患者-获取患者院内标识提示信息
export function getMedRecNVarTips(params) {
  return request({
    url: '/api/web/system/researchPatient/add/getMedRecNVarTips',
    method: 'get',
    params: params
  });
}
// 研究中的变量数量
export function getResearchFieldNum(params) {
  return request({
    url: "/api/web/system/var/detail/research/num",
    method: 'get',
    params: params
  });
}

// 研究类型设置方式
export function setResearchActionType(data) {
  return request({
    url: '/api/web/system/research/set/import',
    method: 'post',
    data: data
  });
}

// 创建研究到预览步骤
export function finishResearchToPreview(data) {
  return request({
    url: '/api/web/system/research/finish/create',
    method: 'post',
    data: data
  });
}

// 完成自定义创建研究
export function endCustomResearch(data) {
  return request({
    url: '/api/web/system/research/preview/finish',
    method: 'post',
    data: data
  });
}

// 研究下获取所有的模块分组变量
export function getAllVsByResearchId(params) {
  return request({
    url: '/api/web/system/var/detail/list/module/hole',
    method: 'get',
    params: params
  });
}
export function copyResearch(data) {
  return request({
    url: '/api/web/system/research/copy',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  });
}

// 创建中的研究数据
export function modifyResearchNum(params) {
  return request({
    url: '/api/web/system/research/modify/nums',
    method: 'get',
    params: params
  });
}

// 通过EXCELL导入变量
export function importResearchVsByExcell(data) {
  return request({
    url: '/api/web/system/var/detail/import/excel',
    method: 'post',
    data: data
  });
}

// 确认导入
export function confirmImportResearchVsByExcell(data) {
  return request({
    url: '/api/web/system/var/detail/import/confirm',
    method: 'post',
    data: data
  });
}
// 下载模板
export function getDowload() {
  return request({
    url: '/api/web/system/var/detail/download/template',
    method: 'get',
    responseType: 'blob'
  });
}

// 初始化删除编辑锁和临时数据
export function initDeleteTempData(params) {
  return request({
    url: '/api/web/system/research/delete/temp',
    method: 'get',
    params: params
  });
}

// 更新临时数据和编辑锁
export function updateTempData(params) {
  return request({
    url: '/api/web/system/research/update/temp',
    method: 'get',
    params: params
  });
}
export function researchDeleteConfig(data) {
  return request({
    url: '/api/web/system/research/delete/config',
    method: 'post',
    data: data
  });
}
export function getUserResearchPermission(params) {
  return request({
    url: '/api/web/system/researchUser/queryResearchResource',
    method: 'get',
    params: params
  });
}

// 获取标准研究介绍
export function getIntroduction(params) {
  return request({
    url: '/api/web/system/diseaseType/queryIntroduction',
    method: 'get',
    params: params
  });
}
export function researchConfigLock(params) {
  return request({
    url: '/api/web/system/research/lock',
    method: 'get',
    params: params
  });
}