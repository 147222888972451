var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "item-field"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "action"
  }, [_c("el-select", {
    staticClass: "full-select",
    attrs: {
      disabled: _vm.formSelectTriggerVs,
      placeholder: "请选择"
    },
    on: {
      change: _vm.change
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, _vm._l(_vm.vsTypes, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.typeName,
        value: item.typeCode
      }
    });
  }), 1)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "label sw"
  }, [_vm._v("变量类型"), _c("span", {
    staticClass: "require"
  }, [_vm._v("*")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };