export default {
  name: 'TreeSelect',
  props: {
    value: [Number, String],
    treeKey: Object,
    data: {
      type: [Array, Object]
    }
  },
  data: function data() {
    return {
      selectValue: this.value,
      selectName: '',
      selectItem: {},
      selectKeys: [],
      id: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      options: []
    };
  },
  watch: {
    treeKey: function treeKey(value) {
      if (this.selectValue) {
        this.selectName = value[this.selectValue].name;
      }
    }
  },
  created: function created() {
    console.log(this.treeKey, this.value);
    // 获取到回显数据
  },

  methods: {
    handleNodeClick: function handleNodeClick(data) {
      if (!data.id || !data.typeCode) {
        return;
      }
      this.selectName = data.name;
      this.selectItem = data;
      this.$emit('change', data);
      this.$refs.selectUpResId.blur();
    },
    handleClear: function handleClear() {
      this.selectItem = {};
      this.selectName = '';
    },
    setSelectName: function setSelectName(list, ids) {
      var _this = this;
      list.children.forEach(function (item) {
        if (item.id === ids) {
          // this.hxlist.push(item.name)
        } else {
          if (item.children != null) {
            _this.setSelectName(item, ids);
          }
        }
      });
    }
  }
};