export default {
  title: 'INCREASE-WEB',
  egTitle: '',
  subtitle: 'INCREASE',
  copyright: '',
  isFirstPage: true,
  // 配置首页不可关闭
  key: 'INCREASE',
  // 配置主键,目前用于存储
  whiteList: ['/user_login', '/404', '/401', '/lock'],
  // 配置无权限可以访问的页面
  whiteTagList: ['/user_login', '/404', '/401', '/lock'],
  // 配置不添加tags页面
  fistPage: {
    label: '首页',
    // 首页
    value: '/wel/index',
    params: {},
    query: {},
    group: [],
    close: false
  }
};