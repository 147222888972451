var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer",
    attrs: {
      id: "el-main-footer"
    }
  }, [_c("div", {
    staticClass: "flexDiv"
  }, [_c("div", {
    staticClass: "flexItem2"
  }, [_c("router-link", {
    attrs: {
      to: "/research/list"
    }
  }, [_c("img", {
    staticClass: "footerLogo",
    attrs: {
      src: require("@/assets/images/footerLogo.png"),
      alt: "footerLogo"
    }
  })])], 1), _vm._v(" "), _vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flexItem",
    staticStyle: {
      width: "40%",
      "text-align": "center"
    }
  }, [_c("span", [_c("a", {
    attrs: {
      href: "/article",
      target: "_blank"
    }
  }, [_vm._v("INCREASE动态")])]), _vm._v(" |\n      "), _vm._v(" "), _c("span", [_c("a", {
    attrs: {
      href: "/about",
      target: "_blank"
    }
  }, [_vm._v("关于我们")])]), _vm._v(" |\n      "), _c("span", [_c("a", {
    attrs: {
      href: "/siteSecurity",
      target: "_blank"
    }
  }, [_vm._v("安全概述")])]), _vm._v(" |\n      "), _c("span", [_c("a", {
    attrs: {
      href: "/sitePrivacy",
      target: "_blank"
    }
  }, [_vm._v("隐私概述")])]), _vm._v(" |\n      "), _c("span", [_c("a", {
    attrs: {
      href: "/siteInfoLegal",
      target: "_blank"
    }
  }, [_vm._v("法律条款")])]), _vm._v(" |\n      "), _c("span", [_c("a", {
    attrs: {
      href: "/agreement",
      target: "_blank"
    }
  }, [_vm._v("用户协议")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flexItem",
    staticStyle: {
      width: "25%",
      "text-align": "right"
    }
  }, [_c("a", {
    attrs: {
      href: "",
      target: "_blank"
    }
  }, [_vm._v("© 2023 白求恩公益基金会版权所有")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flexItem",
    staticStyle: {
      width: "25%",
      "text-align": "right"
    }
  }, [_c("a", {
    attrs: {
      href: "https://www.beian.gov.cn/portal/index.do",
      target: "_blank"
    }
  }, [_vm._v("沪公网安备 31010402009240 沪ICP备18047897号-3")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };