function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { accountLogin, phoneLogin, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { researchAll } from '@/api/register';
var user = {
  state: {
    token: getToken(),
    isNewLogin: false,
    user: {},
    roles: [],
    // 第一次加载菜单时用到
    loadMenus: false,
    userIsRegister: false,
    // 区分用户是否已经注册过
    researchCollect: [],
    researchCollectMap: {}
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
      state.userIsRegister = !(user.userLevel === 1);
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    },
    // 用户初次登录
    SET_IS_NEW_LOGIN: function SET_IS_NEW_LOGIN(state, payload) {
      state.isNewLogin = payload;
    },
    SET_RESEARCH_COLLECT: function SET_RESEARCH_COLLECT(state, payload) {
      var obj = {};
      state.researchCollect = payload;
      payload.forEach(function (v) {
        obj[v.id] = v.name;
      });
      state.researchCollectMap = obj;
    }
  },
  actions: {
    // 用户研究集
    GetResearcCollect: function GetResearcCollect(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        researchAll().then(function (res) {
          console.log(res.data);
          commit('SET_RESEARCH_COLLECT', res.data);
          resolve(res);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登录
    Login: function Login(_ref2, userInfo) {
      var commit = _ref2.commit;
      console.log('userInfouserInfouserInfo', userInfo);
      var rememberMe = userInfo.rememberMe;
      return new Promise(function (resolve, reject) {
        var action = null;
        if (userInfo.type === '1') {
          action = accountLogin;
          delete userInfo.phone;
          delete userInfo.loginCode;
        } else if (userInfo.type === '2') {
          action = phoneLogin;
          delete userInfo.loginName;
          delete userInfo.password;
        }
        delete userInfo.type;
        if (!action) {
          return reject();
        }
        action(userInfo).then(function (res) {
          console.log('获取个人信息action');
          console.log(res);
          setToken(res.data.token, rememberMe);
          console.log(getToken());
          commit('SET_TOKEN', res.data.token);
          var webUser = _objectSpread(_objectSpread({}, res.data.webUser), {}, {
            isNew: res.data.isNew
          });
          setUserInfo(webUser, commit, false);
          // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
          commit('SET_LOAD_MENUS', true);
          commit('SET_IS_NEW_LOGIN', res.data.isNew);
          resolve(webUser);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref3) {
      var commit = _ref3.commit;
      return new Promise(function (resolve, reject) {
        getInfo().then(function (res) {
          setUserInfo(res.data, commit);
          researchAll().then(function (all) {
            commit('SET_RESEARCH_COLLECT', all.data);
            resolve(res);
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve, reject) {
        logOut(commit);
        resolve();
        /* logout().then(res => {
          logOut(commit)
          resolve()
        }).catch(error => {
          logOut(commit)
          reject(error)
        })*/
      });
    },
    updateLoadMenus: function updateLoadMenus(_ref5) {
      var commit = _ref5.commit;
      return new Promise(function (resolve, reject) {
        commit('SET_LOAD_MENUS', false);
      });
    }
  }
};
export var logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  commit('SET_RESEARCH_INFO', {});
  removeToken();
};
export var setUserInfo = function setUserInfo(res, commit) {
  var addRole = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  // 默认是自动添加权限，因为登录有bug.登录的时候返回信息。和myDetail信息不一致。所以不增加角色，强制获取用户
  if (addRole) {
    if (!res.roles || res.roles.length === 0) {
      commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT']);
    } else {
      commit('SET_ROLES', res.roles);
    }
  }
  commit('SET_USER', _objectSpread(_objectSpread({}, res), {}, {
    // jobName: res.realName ? res.realName.charAt(0) + '医生' : null
    jobName: res.realName ? (res.realName.length > 10 ? res.realName.substring(0, 10) + '...' : res.realName) + '医生' : null
  }));
};
export default user;