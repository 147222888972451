function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import RightPanel from '@/components/RightPanel';
import Footer from '@/components/Footer';
import FixTool from '@/layout/components/FixTool';
import { AppMain, Navbar, Settings, Sidebar } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { mapState } from 'vuex';
import Theme from '@/components/ThemePicker';
import Cookies from 'js-cookie';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    Theme: Theme,
    Footer: Footer,
    FixTool: FixTool
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      footerVisible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        // hideSidebar: !this.sidebar.opened,
        hideSidebar: false,
        // openSidebar: this.sidebar.opened,
        openSidebar: true
        // withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === 'mobile'
      };
    }
  }),
  mounted: function mounted() {
    if (Cookies.get('theme')) {
      this.$refs.theme.theme = Cookies.get('theme');
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: Cookies.get('theme')
      });
    }
    window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      // this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    handleScroll: function handleScroll() {
      var scrollTop = document.documentElement.scrollTop; // 滚动高度
      var clientHeight = document.documentElement.clientHeight; // 可视高度
      var scrollHeight = document.documentElement.scrollHeight; // 内容高度
      var heights = scrollHeight - (scrollTop + clientHeight);
      if (heights < 10) {
        this.footerVisible = true;
      } else {
        this.footerVisible = false;
      }
    }
  }
};