import { VsField } from './vsField';
import { SPECIAL_CODE } from '../enum/form';

/**
 * 变量分组
 * @returns {*}
 * @constructor
 */
export function VsGroup() {
  if (!(this instanceof VsGroup)) {
    return new VsGroup().initialize(arguments);
  } else if (arguments.length) {
    this.initialize(arguments);
  }
}
var treeVsMap = [];
var specialCodeArr = [];
function getVsByCode(list) {
  list.forEach(function (v) {
    if (v.hasOwnProperty('varCode')) {
      specialCodeArr.push(v.varCode);
      if (v.child && v.child.length) {
        getVsByCode(v.child);
      }
    } else {
      if (v.child && v.child.length) {
        getVsByCode(v.child);
      }
    }
  });
}
function initChildVs(list, newList) {
  list.forEach(function (v) {
    var newVs = new VsField(v);
    newVs.resTransToEntity();
    if (v.child && v.child.length) {
      newVs.child = [];
      initChildVs(v.child, newVs.child);
    } else {
      newVs.child = [];
    }
    newList.push(newVs);
  });
}
Object.assign(VsGroup.prototype, {
  child: [],
  model: 2,
  expand: true,
  id: null,
  name: '',
  sort: null
});
VsGroup.prototype.initialize = function (sources) {
  treeVsMap = [];
  for (var i = 0; i < sources.length; ++i) {
    var source = sources[i];
    for (var prop in source) {
      if (Object.prototype.hasOwnProperty.call(source, prop)) {
        if (source[prop] === undefined) {
          delete this[prop];
        } else {
          this[prop] = source[prop];
        }
      }
    }
  }
  // 核心分组变量
  if (this.child && this.child.length) {
    // 递归
    initChildVs(this.child, treeVsMap);
    this.child = treeVsMap;
  } else {
    this.child = [];
  }
  this.expand = true;
  return this;
};

/**
 * 统一刷分组下字段-变量组按钮权限
 */
VsGroup.prototype.updateGroupChildControl = function (type, oldIndex, indexIndex) {
  var _this = this;
  // this.child.forEach((vf, index) => {
  //   vf._addGroup = index === this.child.length - 1
  //   vf._delGroup = index > 0 && index === this.child.length - 1
  // })
  if (type === 'add') {
    this.child[oldIndex]._addGroup = false;
    this.child[indexIndex]._addGroup = true;
    this.child[indexIndex]._delGroup = true;
  } else if (type === 'del') {
    this.child[oldIndex]._addGroup = true;
    var double = this.child.filter(function (v) {
      return v.id === _this.child[oldIndex].id;
    }).length;
    this.child[oldIndex]._delGroup = double > 1;
  }
};
VsGroup.prototype.addEmptyGroup = function () {};

/**
 * 通过索引增加变量组字段
 * @param index
 */
VsGroup.prototype.addGroupChildCloneVs = function (index) {
  this.child.splice(index, 0, this.child[index].cloneEmpty());
  this.updateGroupChildControl('add', index, index + 1);
};

/**
 * 通过索引删除变量组字段
 * @param index
 */
VsGroup.prototype.delGroupChildVs = function (index) {
  this.child.splice(index, 1);
  this.updateGroupChildControl('del', index - 1);
};

/**
 * 分组下树形字段排序
 */
VsGroup.prototype.groupSortTreeVs = function () {
  var res = [];
  function eachSort(list, resList) {
    list.forEach(function (v, i) {
      var oChild = {};
      v.sort = i + 1;
      oChild.sort = v.sort;
      oChild.id = v.id;
      resList.push(oChild);
      if (v.child && v.child.length) {
        oChild.child = [];
        eachSort(v.child, oChild.child);
      }
    });
  }
  eachSort(this.child, res);
  return res;
};

// 校验模块下是否有特殊的变量。存在的话禁止操作
VsGroup.prototype.validHasSpecialCode = function () {
  specialCodeArr = [];
  getVsByCode(this.child);
  return specialCodeArr.some(function (v) {
    return SPECIAL_CODE.includes(v);
  });
};