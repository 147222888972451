function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import website from '@/config/website';
var keyName = website.key + '-';
function evil(fn) {
  var Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn('return ' + fn)();
}
function validatenull(val) {
  if (typeof val === 'boolean') {
    return false;
  }
  if (typeof val === 'number') {
    return false;
  }
  if (val instanceof Array) {
    if (val.length === 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true;
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true;
    return false;
  }
  return false;
}

/**
 * 存储localStorage
 */
export var setStore = function setStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var content = params.content,
    type = params.type;
  var name = params.name;
  name = keyName + name;
  var obj = {
    dataType: _typeof(content),
    content: content,
    type: type,
    datetime: new Date().getTime()
  };
  if (type) {
    window.sessionStorage.setItem(name, JSON.stringify(obj));
  } else {
    window.localStorage.setItem(name, JSON.stringify(obj));
  }
};
/**
 * 获取localStorage
 */

export var getStore = function getStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name;
  var debug = params.debug;
  name = keyName + name;
  var obj = {};
  var content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch (e) {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType === 'string') {
    content = obj.content;
  } else if (obj.dataType === 'number') {
    content = Number(obj.content);
  } else if (obj.dataType === 'boolean') {
    content = evil(obj.content);
  } else if (obj.dataType === 'object') {
    content = obj.content;
  }
  return content;
};
/**
 * 删除localStorage
 */
export var removeStore = function removeStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var name = params.name;
  var type = params.type;
  name = keyName + name;
  if (type) {
    window.sessionStorage.removeItem(name);
  } else {
    window.localStorage.removeItem(name);
  }
};

/**
 * 获取全部localStorage
 */
export var getAllStore = function getAllStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var list = [];
  var type = params.type;
  if (type) {
    for (var i = 0; i <= window.sessionStorage.length; i++) {
      list.push({
        name: window.sessionStorage.key(i),
        content: getStore({
          name: window.sessionStorage.key(i),
          type: 'session'
        })
      });
    }
  } else {
    for (var _i = 0; _i <= window.localStorage.length; _i++) {
      list.push({
        name: window.localStorage.key(_i),
        content: getStore({
          name: window.localStorage.key(_i)
        })
      });
    }
  }
  return list;
};

/**
 * 清空全部localStorage
 */
export var clearStore = function clearStore() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var type = params.type;
  if (type) {
    window.sessionStorage.clear();
  } else {
    window.localStorage.clear();
  }
};