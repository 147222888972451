import _ from 'lodash';

/**
 * 树删除节点
 * @param treeData
 */
export function treeDeleteNodeById(treeData, id) {
  var childKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'child';
  function traversal(data) {
    console.log(data, id);
    var _loop = function _loop(_i) {
      var info = data[_i];
      console.log(info.id, id);
      if (info[childKey]) {
        if (info[childKey].length > 0) {
          traversal(info[childKey], id, childKey);
        }
        if (info.id === id) {
          var index = data.findIndex(function (item) {
            return info.id === item.id;
          });
          data.splice(index, 1);
          _i--;
        }
      }
      i = _i;
    };
    for (var i = 0; i < data.length; i++) {
      _loop(i);
    }
  }
  traversal(treeData);
}

/**
 * 扁平化：将具有层级递进关系结构的 tree 数据扁平化
 *
 * @param treeList 有层级递进关系结构的 tree 数据
 * @param flatList 用于接收扁平化结果的变量
 * @returns {*} 返回扁平化结果
 */
export function treeToFlat(treeList, flatList, parent) {
  var childKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'child';
  treeList.map(function (e) {
    e._parent = parent;
    flatList.push(e);
    // 递归：有条件的自己调用自己，条件是 e.children.length 为真
    if (e[childKey] && e[childKey].length) {
      treeToFlat(e[childKey], flatList, _.cloneDeep(e));
    }
  });
  // console.log('扁平化后：', flatList)
  return flatList;
}