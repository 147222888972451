var getters = {
  deployUploadApi: function deployUploadApi(state) {
    return state.api.deployUploadApi;
  },
  databaseUploadApi: function databaseUploadApi(state) {
    return state.api.databaseUploadApi;
  },
  size: function size(state) {
    return state.app.size;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  user: function user(state) {
    return state.user.user;
  },
  isNewLogin: function isNewLogin(state) {
    return state.user.isNewLogin;
  },
  loadMenus: function loadMenus(state) {
    return state.user.loadMenus;
  },
  userIsRegister: function userIsRegister(state) {
    return state.user.userIsRegister;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  permission_zindex: function permission_zindex(state) {
    return state.permission.zIndex;
  },
  permissionResearchRole: function permissionResearchRole(state) {
    return state.permission.researchRolePermission;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  imagesUploadApi: function imagesUploadApi(state) {
    return state.api.imagesUploadApi;
  },
  baseApi: function baseApi(state) {
    return state.api.baseApi;
  },
  downloadApi: function downloadApi(state) {
    return state.api.downloadApi;
  },
  fileUploadApi: function fileUploadApi(state) {
    return state.api.fileUploadApi;
  },
  updateAvatarApi: function updateAvatarApi(state) {
    return state.api.updateAvatarApi;
  },
  qiNiuUploadApi: function qiNiuUploadApi(state) {
    return state.api.qiNiuUploadApi;
  },
  sqlApi: function sqlApi(state) {
    return state.api.sqlApi;
  },
  swaggerApi: function swaggerApi(state) {
    return state.api.swaggerApi;
  },
  sidebarRouters: function sidebarRouters(state) {
    return state.permission.sidebarRouters;
  },
  researchCollect: function researchCollect(state) {
    return state.user.researchCollect;
  },
  researchCollectMap: function researchCollectMap(state) {
    return state.user.researchCollectMap;
  },
  // 表单
  formDiseaseParam: function formDiseaseParam(state) {
    return state.form.diseaseParam;
  },
  formResearchInfo: function formResearchInfo(state) {
    return state.form.researchInfo;
  },
  formResearchId: function formResearchId(state) {
    return state.form.researchInfo.id;
  },
  formResearchNum: function formResearchNum(state) {
    return state.form.researchNum;
  },
  formOriginActionType: function formOriginActionType(state) {
    return state.form.originActionType;
  },
  formResearchLoading: function formResearchLoading(state) {
    return state.form.researchLoading;
  },
  formResearchVarNum: function formResearchVarNum(state) {
    return state.form.researchVarNum;
  },
  formResearchMGVs: function formResearchMGVs(state) {
    return state.form.researchMGVs;
  },
  formFieldTypes: function formFieldTypes(state) {
    return state.form.fieldTypes;
  },
  formFieldPreview: function formFieldPreview(state) {
    return state.form.fieldPreview;
  },
  formGroupCollapse: function formGroupCollapse(state) {
    return state.form.groupCollapse;
  },
  formSelectModule: function formSelectModule(state) {
    return state.form.selectModule;
  },
  formSelectField: function formSelectField(state) {
    return state.form.selectField;
  },
  formSelectReadonly: function formSelectReadonly(state) {
    return state.form.selectVsReadonly;
  },
  formSelectVsId: function formSelectVsId(state) {
    return state.form.selectVsId;
  },
  formSelectTriggerVs: function formSelectTriggerVs(state) {
    return state.form.selectIsTriggerVs;
  },
  formRefreshVs: function formRefreshVs(state) {
    return state.form.refreshVs;
  },
  formFieldEdit: function formFieldEdit(state) {
    return state.form.fieldEdit;
  },
  formResearchConfigEnd: function formResearchConfigEnd(state) {
    return state.form.researchConfigEnd;
  },
  formVarTip: function formVarTip(state) {
    return state.form.formVarTip;
  },
  formClassification: function formClassification(state) {
    return state.form.formClassification;
  },
  // 获取是否特殊模块
  formEditable: function formEditable(state) {
    return state.form.formEditable;
  },
  // 是否是标准模块或者引用得自定义变量 0 是 1否
  formStepIndex: function formStepIndex(state) {
    return state.form.stepIndex;
  },
  // 概览页获取索引
  formOverviewIndex: function formOverviewIndex(state) {
    return state.form.formOverviewIndex;
  },
  // 质疑规则 和自动结算规则 和ocr //拨打电话
  challengeRules: function challengeRules(state) {
    return state.rules.challengeRules;
  },
  countRules: function countRules(state) {
    return state.rules.countRules;
  },
  scrollTo: function scrollTo(state) {
    return state.rules.scrollTo;
  },
  ocrNum: function ocrNum(state) {
    return state.rules.ocrNum;
  },
  createData: function createData(state) {
    return state.rules.createData;
  },
  questionIgnore: function questionIgnore(state) {
    return state.rules.questionIgnore;
  },
  surgicalDate: function surgicalDate(state) {
    return state.rules.surgicalDate;
  }
};
export default getters;